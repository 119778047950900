import AsyncStorage from "@react-native-async-storage/async-storage";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Button, DeleteIcon, Input, TextArea } from "native-base";
import React, { useContext, useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import useDeepCompareEffect from "use-deep-compare-effect";
import { AppBar } from "../../components/AppBar";
import { CustomTrophyContext } from "../../contexts";
import { sharedStyles } from "../../sharedStyles";
import { alert } from "../../utils/Alert";
import { ITrophy } from "./types";

export const useCustomTrophies = (): [
  ITrophy[],
  (newTrophies: ITrophy[]) => void
] => {
  const customTrophiesKey = "custom-trophies";
  const { customTrophies, setCustomTrophies } = useContext(CustomTrophyContext);

  useEffect(() => {
    async function _() {
      const savedTrophies = JSON.parse(
        (await AsyncStorage.getItem(customTrophiesKey)) ?? "[]"
      );
      setCustomTrophies(savedTrophies);
    }
    _();
  }, []);

  useDeepCompareEffect(() => {
    AsyncStorage.setItem(customTrophiesKey, JSON.stringify(customTrophies));
  }, [customTrophies]);

  const publicSetTrophies = (newTrophies: ITrophy[]) => {
    setCustomTrophies(newTrophies);
    setCustomTrophies(newTrophies);
  };

  return [customTrophies, publicSetTrophies];
};

export const AddCustomTrophy: React.FunctionComponent = () => {
  const [id, setId] = useState(String(Date.now()));
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const route = useRoute();
  //@ts-ignore
  const trophyId = route.params?.trophyId;
  const [customTrophies, setCustomTrophies] = useCustomTrophies();
  const targetTrophy = customTrophies.find((t) => t.id === trophyId);

  useDeepCompareEffect(() => {
    if (targetTrophy) {
      setId(targetTrophy.id);
      setName(targetTrophy.name);
      setDesc(targetTrophy.desc);
    }
  }, [customTrophies]);

  const handleSave = async () => {
    const newTrophy: ITrophy = { id, name, desc };
    if (targetTrophy) {
      const newTrophies = customTrophies.map((t) => {
        if (t.id === newTrophy.id) {
          return newTrophy;
        } else {
          return t;
        }
      });
      setCustomTrophies(newTrophies);
    } else {
      // New Save
      setCustomTrophies([...customTrophies, newTrophy]);
    }
    navigation.goBack();
  };

  const handleDelete = () => {
    alert(
      "Delete Trophy",
      `Are you sure you want to delete the "${targetTrophy?.name}" trophy?`,
      [
        {
          text: "Cancel",
          style: "cancel",
        },
        {
          text: "OK",
          onPress: () => {
            const newTrophies = customTrophies.filter((t) => t.id !== trophyId);
            setCustomTrophies(newTrophies);

            navigation.goBack();
          },
        },
      ]
    );
  };

  return (
    <View style={sharedStyles.flex1}>
      <AppBar
        title={`${targetTrophy ? "Edit" : "New"} Trophy`}
        actions={[
          {
            icon: DeleteIcon,
            onPress: handleDelete,
          },
        ]}
        goBack
      />
      <ScrollView contentContainerStyle={[sharedStyles.screenContainer]}>
        <Input placeholder="Title" onChangeText={setName} value={name} />
        <TextArea
          autoCompleteType={""}
          placeholder="Description"
          onChangeText={setDesc}
          value={desc}
          flex="1"
          mt="2"
          mb="4"
        />
        <Button mb="2" onPress={handleSave}>
          Save
        </Button>
        <Button variant="ghost" onPress={navigation.goBack}>
          Cancel
        </Button>
      </ScrollView>
    </View>
  );
};
