// On my phone my locale is "en-US"
// This function should convert locale codes like that to a known locale "en-US" => "en"
export function normalizeLocale(locale: string): string {
  if (locale.includes("en")) {
    return "en";
  }
  if (locale.includes("fr")) {
    return "fr";
  }
  if (locale.includes("ru")) {
    return "ru";
  }
  if (locale.includes("pl")) {
    return "pl";
  }

  return "en";
}
