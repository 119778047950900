export const ANALYTICS = {
  EVENTS: {
    MAP_PREFERENCE_CHANGE: "map_preference",
    FAVORITE_CHANGE: "favorite",
    MAP_TYPE: "map_type",
  },
  SCREENS: {
    MAP_LIST_SCREENNAME: "maps",
    MAP_DETAIL_SCREENNAME: "maps_detail",
  },
  JOURNAL: {
    CREATE_JOURNAL_ENTRY: "createJournalEntry",
    CREATE_JOURNAL: "createJournalEntry",
  },
};

export const maps = {
  // baseUrl: "http://localhost:3001"
  baseUrl: "https://silent-cartographer-client.vercel.app",
};

export const SUPPORTED_LOCALES = ["en", "pl", "ru"];
