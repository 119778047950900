import { IInputProps, Input as NBInput } from "native-base";
import React from "react";

export const Input: React.FunctionComponent<IInputProps> = (props) => {
  return (
    <NBInput
      {...props}
      borderColor="primary.300"
      placeholderTextColor="primary.300"
      fontSize="md"
    />
  );
};
