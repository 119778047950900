import { DrawerNavigationProp } from "@react-navigation/drawer";
import { RouteProp, useNavigation } from "@react-navigation/native";
import { Box, Button, Input, KeyboardAvoidingView, Spacer } from "native-base";
import React, { useEffect, useState } from "react";
import { Platform } from "react-native";
import { AppBar } from "../../components/AppBar";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { usePrevious } from "../../hooks/usePrevious";
import { useJournalEntry } from "./hooks/useJournalEntry";
import { putJournalEntry } from "./journalSlice";
import { IParamList } from "./types";

type EditScreenRouteProp = RouteProp<IParamList, "EditNote">;

interface IProps {
  route: EditScreenRouteProp;
}

export const EditNote: React.FunctionComponent<IProps> = (props) => {
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const journalId = useAppSelector((state) => state.currentJournalId);
  const entryId = props.route.params.entryId;
  const { targetJournalEntry } = useJournalEntry(entryId);
  const dispatch = useAppDispatch();

  const [note, setNote] = useState(targetJournalEntry?.note || "");
  const prevNote = usePrevious(note);

  useEffect(() => {
    if (journalId && entryId) {
      if (prevNote !== note) {
        dispatch(putJournalEntry({ journalId, entry: { id: entryId, note } }));
      }
    }
  }, [note, journalId, entryId]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.select({ ios: "padding" })}
      flex="1"
    >
      <AppBar title="Edit Note" goBack />
      <Box flex="1">
        <Input
          value={note}
          onChangeText={setNote}
          placeholder="Enter Notes"
          multiline
          flex="1"
        />
        <Spacer h="4" />
        <Button mb="2" variant="outline" onPress={navigation.goBack}>
          Go Back
        </Button>
      </Box>
    </KeyboardAvoidingView>
  );
};
