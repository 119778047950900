import {
  DrawerContentScrollView,
  DrawerNavigationProp,
} from "@react-navigation/drawer";
import {
  Box,
  Button,
  Center,
  CloseIcon,
  Column,
  Divider,
  FavouriteIcon,
  Hidden,
  Image,
  Pressable,
  Row,
  Text,
} from "native-base";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { backgroundColor, hoverColor } from "../colors";
import { useI18n } from "../hooks/use18n";
import { RouteNames } from "../modules/Maps/constants";
import { getFavoriteRegion } from "../modules/Maps/favoriteRegionSlice";
import { useRegions } from "../modules/Maps/hooks/useRegions";
import { kebabToTitleCase } from "../utils/calculations";
import { TextLogo } from "./TextLogo";
import { MapIcon, BagIcon, TrophyIcon, BookIcon } from "./Icons";
import { DashboardIcon, SettingsIcon } from "./Icons";
import { useNavigation } from "@react-navigation/native";

const DrawerContent: React.FunctionComponent = (props) => {
  const favorite = useSelector(getFavoriteRegion);
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const regions = useRegions();
  const { translate } = useI18n();
  const imageLength = 150;

  const mainLinks = [
    {
      icon: <DashboardIcon size={6} color="white" />,
      label: kebabToTitleCase(translate("modules.dashboard.dashboard")),
      onPress: () => navigation.navigate(RouteNames.Dashboard.name),
    },
    {
      icon: <MapIcon size={6} color="white" />,
      label: kebabToTitleCase(translate("modules.map.map_plural")),
      onPress: () => navigation.navigate(RouteNames.Maps.name),
    },
    {
      icon: <BagIcon size={6} color="white" />,
      label: kebabToTitleCase(translate("modules.item.item")),
      onPress: () => navigation.navigate(RouteNames.Items.name),
    },
    {
      icon: <TrophyIcon size={6} color="white" />,
      label: kebabToTitleCase(translate("modules.trophies.trophy_plural")),
      onPress: () => navigation.navigate(RouteNames.Trophies.name),
    },
    {
      icon: <BookIcon size={6} color="white" />,
      label: kebabToTitleCase(translate("modules.journal.journal")),
      onPress: () => navigation.navigate(RouteNames.Journal.name),
    },
    {
      icon: <SettingsIcon size={6} color="white" />,
      label: kebabToTitleCase(translate("modules.settings.settings")),
      onPress: () => navigation.navigate(RouteNames.Settings.name),
    },
  ];

  return (
    <DrawerContentScrollView {...props}>
      <Column bg={backgroundColor}>
        <Column mb={1}>
          <Hidden till="md">
            <Row justifyContent="center">
              <Image
                w={imageLength}
                h={imageLength}
                rounded="md"
                source={require("./icon.png")}
                alt="Extended Night App Wolf Logo"
              />
            </Row>
          </Hidden>
          <Center h={"12"} mt={[0, 0, 0, 2]} px={1} mx={4}>
            <TextLogo />
            <Divider bg={"primary.400"} mt="3" />
          </Center>
        </Column>
        <Column mx={4} space={1}>
          {mainLinks.map((link) => {
            return (
              <Pressable
                _hover={{
                  bg: hoverColor,
                }}
                rounded="lg"
                key={link.label}
                onPress={link.onPress}
                p={2}
              >
                <Row alignItems="center">
                  {link.icon}
                  <Text color="gray.300" ml={5}>
                    {link.label}
                  </Text>
                </Row>
              </Pressable>
            );
          })}
          <Box mt={3} mb={1}>
            <Text>
              {kebabToTitleCase(translate("modules.map.region_plural"))}
            </Text>

            {regions.map((region) => {
              return (
                <Pressable
                  _hover={{
                    bg: hoverColor,
                  }}
                  rounded="lg"
                  key={region.name}
                  onPress={() => {
                    navigation.navigate({
                      name: RouteNames.Region.name,
                      params: { regionName: region.name },
                    });
                  }}
                  p={2}
                >
                  <Row alignItems="center">
                    {favorite === region.name ? (
                      <FavouriteIcon
                        color={"red.500"}
                        size={4}
                        accessibilityLabel="heart"
                      />
                    ) : (
                      <Box w={4} />
                    )}
                    <Text color="gray.300" ml={5}>
                      {kebabToTitleCase(
                        translate(`modules.map.${region.name}`)
                      )}
                    </Text>
                  </Row>
                </Pressable>
              );
            })}
          </Box>
          <Hidden from="md">
            <Button
              leftIcon={<CloseIcon color="white" />}
              onPress={() => navigation.closeDrawer()}
            >
              {kebabToTitleCase(translate("common.close"))}
            </Button>
          </Hidden>
        </Column>
      </Column>
    </DrawerContentScrollView>
  );
};

const MemoDrawerContent = memo(DrawerContent, () => true);

export { MemoDrawerContent as DrawerContent };
