import AsyncStorage from "@react-native-async-storage/async-storage";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { AddIcon, Text } from "native-base";
import { Line } from "../../components/Line";
import { CustomTrophyContext } from "../../contexts";
import { useI18n } from "../../hooks/use18n";
import { sharedStyles, spacing } from "../../sharedStyles";
import { TrophyCard } from "./components/TrophyCard";
import { ITrophy, ITrophyType } from "./types";
import { getTrophyData } from "./utils/getTrophyData";
import { AppBar } from "../../components/AppBar";

const Tabs = createMaterialTopTabNavigator();

interface IProps {
  trophyType: ITrophyType;
}

export const useCheckedTrophyIds = (): [
  string[],
  React.Dispatch<React.SetStateAction<string[]>>
] => {
  const trophyKey = "checked-trophies";
  const [checkedTrophyIds, setCheckedTrophyIds] = useState<string[]>([]);

  useEffect(() => {
    async function _() {
      const savedTrophies = JSON.parse(
        (await AsyncStorage.getItem(trophyKey)) ?? "[]"
      );

      setCheckedTrophyIds(savedTrophies);
    }
    _();
  }, []);

  useEffect(() => {
    async function _() {
      await AsyncStorage.setItem(trophyKey, JSON.stringify(checkedTrophyIds));
    }
    _();
  }, [checkedTrophyIds]);

  return [checkedTrophyIds, setCheckedTrophyIds];
};

export const Trophy: React.FunctionComponent<IProps> = (props) => {
  const { customTrophies } = useContext(CustomTrophyContext);
  const { translate } = useI18n();
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const [checkedTrophyIds, setCheckedTrophyIds] = useCheckedTrophyIds();
  const [activeTab, setActiveTab] = useState("todo");
  const [trophies, setTrophies] = useState(
    props.trophyType === "custom"
      ? customTrophies
      : getTrophyData(props.trophyType)
  );
  const completedTrophies = trophies.filter((t: ITrophy) =>
    checkedTrophyIds.includes(t.id)
  );
  const todoTrophies = trophies.filter(
    (t: ITrophy) => !checkedTrophyIds.includes(t.id)
  );

  /**
   * This fixes the tabs from auto navigating to the first child
   * in the tab screens when re renders happen.
   *
   * Example: Someone un checks a completed trophy, the state for checked trophies updates
   * the component re renders, Tab navigator re renders and puts the first tab as the active one
   */
  useEffect(() => {
    // @ts-ignore
    const unsubscribe = navigation.addListener("tabPress", (e) => {
      setActiveTab(e.target || "");
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    if (props.trophyType === "custom") {
      setTrophies(customTrophies);
    }
  }, [customTrophies]);

  useEffect(() => {
    if (props.trophyType === "custom") {
      AsyncStorage.getItem("custom-trophies").then((data) => {
        const customTrophies = JSON.parse(data ?? "[]");
        setTrophies(customTrophies);
      });
    }
  }, []);

  const handleCardClick = useCallback(
    (id: string) => {
      if (checkedTrophyIds.includes(id)) {
        setCheckedTrophyIds(checkedTrophyIds.filter((t) => t !== id));
      } else {
        setCheckedTrophyIds([...checkedTrophyIds, id]);
      }
    },
    [checkedTrophyIds]
  );

  const renderTrophyCard = useCallback(
    ({ item }: { item: ITrophy }) => {
      return (
        <TrophyCard
          trophy={item}
          isChecked={checkedTrophyIds.includes(item.id)}
          onPress={handleCardClick}
          trophyType={props.trophyType}
        />
      );
    },
    [checkedTrophyIds]
  );

  const renderEmpty = useCallback(() => {
    return (
      <View style={styles.central}>
        <Text>No Trophies</Text>
      </View>
    );
  }, []);

  return (
    <View style={sharedStyles.flex1}>
      <AppBar
        goBack
        title={translate(`modules.trophies.${props.trophyType}`)}
        actions={
          props.trophyType === "custom"
            ? [
                {
                  icon: AddIcon,
                  onPress: () => navigation.navigate("AddCustomTrophy"),
                },
              ]
            : undefined
        }
      />

      <Tabs.Navigator
        initialRouteName={activeTab}
        screenOptions={{
          tabBarIndicatorStyle: { backgroundColor: "white" },
        }}
      >
        <Tabs.Screen
          name="todo"
          options={{ tabBarLabel: `To Do (${todoTrophies.length})` }}
        >
          {() => (
            <FlatList<ITrophy>
              contentContainerStyle={styles.grow}
              style={sharedStyles.screenContainer}
              data={todoTrophies}
              ItemSeparatorComponent={Line}
              ListEmptyComponent={renderEmpty}
              renderItem={renderTrophyCard}
              keyExtractor={(i) => i.id}
            />
          )}
        </Tabs.Screen>
        <Tabs.Screen
          name="completed"
          options={{ tabBarLabel: `Completed (${completedTrophies.length})` }}
        >
          {() => (
            <FlatList<ITrophy>
              contentContainerStyle={styles.grow}
              style={sharedStyles.screenContainer}
              data={completedTrophies}
              ItemSeparatorComponent={Line}
              renderItem={renderTrophyCard}
              ListEmptyComponent={renderEmpty}
              keyExtractor={(i) => i.id}
            />
          )}
        </Tabs.Screen>
      </Tabs.Navigator>
    </View>
  );
};

const styles = StyleSheet.create({
  appbar: { backgroundColor: "transparent" },
  central: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  grow: { flexGrow: 1, paddingVertical: spacing.md },
});
