export const trophies: Array<{ id: string }> = [
  {
    id: "the-first-of-many",
  },
  {
    id: "you-made-it",
  },
  {
    id: "ready-for-when-the-shtf",
  },
  {
    id: "centurion",
  },
  {
    id: "the-will-to-live",
  },
  {
    id: "exploration-game",
  },
  {
    id: "waste-no-want-not",
  },
  {
    id: "night-walker",
  },
  {
    id: "beneath-a-starry-sky",
  },
  {
    id: "silent-hunter",
  },
  {
    id: "pacifist",
  },
  {
    id: "it-was-this-big",
  },
  {
    id: "wrapped-in-furs",
  },
  {
    id: "living-off-the-land",
  },
  {
    id: "natural-healer",
  },
  {
    id: "happy-harvester",
  },
  {
    id: "stone-age-sniper",
  },
  {
    id: "skilled-survivor",
  },
  {
    id: "face-the-impossible",
  },
  {
    id: "don-t-starve",
  },
  {
    id: "faithful-cartographer",
  },
  {
    id: "resolute-outfitter",
  },
  {
    id: "penitent-scholar",
  },
  {
    id: "timberwolf-mountain",
  },
  {
    id: "desolation-point",
  },
  {
    id: "deep-forest",
  },
  {
    id: "challenge-mastery",
  },
];

export const hiddenTrophies = [
  {
    id: "your-journey-begins",
  },
  {
    id: "paradise-lost",
  },
  {
    id: "the-long-winter",
  },
  {
    id: "losing-a-child-is-like",
  },
  {
    id: "leaving-the-old-world-behind",
  },
  {
    id: "the-old-trapper",
  },
  {
    id: "lights-in-the-sky",
  },
  {
    id: "graduation-day",
  },
  {
    id: "freight-train-of-hate-hunger",
  },
  {
    id: "you-ll-be-with-her-soon",
  },
  {
    id: "too-big-to-fail",
  },
  {
    id: "sounds-like-some-kind-of-indie-band",
  },
  {
    id: "cache-mastery",
  },
  {
    id: "community-service",
  },
  {
    id: "there-will-be-blood",
  },
  {
    id: "save-our-souls",
  },
  {
    id: "hippocratic-oath",
  },
  {
    id: "he-lives",
  },
  {
    id: "the-crossroads-elegy",
  },
  {
    id: "tall-tales",
  },
  {
    id: "every-last-one",
  },
];
