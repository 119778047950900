import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import { Button, Menu, Pressable, Text, useTheme } from "native-base";
import React, { useCallback } from "react";
import {
  LayoutAnimation,
  Platform,
  StyleSheet,
  UIManager,
  View,
} from "react-native";
import { NavigationRow } from "../../../components/NavigationRow";
import { useI18n } from "../../../hooks/use18n";
import { sharedStyles, spacing } from "../../../sharedStyles";
import { kebabToTitleCase } from "../../../utils/calculations";
import { capitalizeWordsInString } from "../../../utils/capitalizeWordsInString";
import { useRegions } from "../../Maps/hooks/useRegions";
import { IEntry } from "../types";
import { BagIcon, PencilBoxIcon } from "../../../components/Icons";
import { alert } from "../../../utils/Alert";
interface EntryCardProps {
  journalEntry: IEntry;
  copy: (entry: IEntry) => void;
  delete: (entry: IEntry) => void;
}

if (Platform.OS === "android") {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

export const EntryCard: React.FunctionComponent<EntryCardProps> = (props) => {
  const { translate } = useI18n();
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const theme = useTheme();
  const region = useRegions();
  const targetRegion = region.find((r) => r.name === props.journalEntry.mapId);

  const handleDeletePress = useCallback(() => {
    alert(
      capitalizeWordsInString(
        translate("modules.journal.delete-journal-entry")
      ),
      capitalizeWordsInString(
        translate("modules.journal.delete-journal-entry-desc", {
          day: props.journalEntry.day,
        })
      ),
      [
        {
          text: capitalizeWordsInString(translate("common.cancel")),
          style: "cancel",
        },
        {
          text: capitalizeWordsInString(translate("modules.journal.delete")),
          style: "destructive",
          onPress: () => {
            LayoutAnimation.configureNext(
              LayoutAnimation.Presets.easeInEaseOut
            );
            props.delete(props.journalEntry);
          },
        },
      ]
    );
  }, []);

  const handleCopyPress = useCallback(() => {
    props.copy(props.journalEntry);
  }, [props.journalEntry, props.copy]);

  const handleEditPress = useCallback(
    (screenName: string) => {
      navigation.navigate(screenName, { entryId: props.journalEntry.id });
    },
    [props.journalEntry.id]
  );

  const note = props.journalEntry.note || "";
  const location = props.journalEntry.location || "";
  return (
    <View
      style={[styles.journalEntry, { borderColor: theme.colors.primary[600] }]}
    >
      <View>
        <View style={[sharedStyles.row, styles.cardText]}>
          <View>
            <Text fontSize="lg">
              {translate(`modules.map.${props.journalEntry.mapId}`)}
            </Text>
            {location !== "" && <Text>{location}</Text>}
          </View>
          <Text textTransform="capitalize" style={[styles.day]}>
            {translate("modules.journal.day")} {props.journalEntry.day}
          </Text>
        </View>

        {props.journalEntry.items.length > 0 && (
          <View style={[sharedStyles.row, sharedStyles.itemsCenter]}>
            <BagIcon color={"white"} size={35} />

            <Pressable
              style={[{ marginLeft: spacing.md }, sharedStyles.flex1]}
              onPress={() =>
                navigation.navigate("EditItems", {
                  entryId: props.journalEntry.id,
                  initiallyShowOnlyChecks: true,
                })
              }
            >
              <NavigationRow>
                <Text
                  style={[{ fontStyle: "italic" }]}
                  numberOfLines={2}
                  ellipsizeMode={"tail"}
                >
                  {props.journalEntry.items
                    .map((i) => `(${i.quantity}) ` + kebabToTitleCase(i.id))
                    .join(", ")}
                </Text>
              </NavigationRow>
            </Pressable>
          </View>
        )}

        {note.length > 0 && (
          <View style={[sharedStyles.row, sharedStyles.itemsCenter]}>
            <PencilBoxIcon color={"white"} size={35} />
            <Pressable
              style={[{ marginLeft: spacing.md }, sharedStyles.flex1]}
              onPress={() =>
                navigation.navigate("EditNote", {
                  entryId: props.journalEntry.id,
                })
              }
            >
              <NavigationRow>
                <Text
                  style={[{ fontStyle: "italic" }]}
                  numberOfLines={2}
                  ellipsizeMode={"tail"}
                >
                  {props.journalEntry.note}
                </Text>
              </NavigationRow>
            </Pressable>
          </View>
        )}
      </View>
      <View style={[sharedStyles.row, styles.cardActions]}>
        <Button
          textTransform="capitalize"
          variant="ghost"
          onPress={handleCopyPress}
        >
          <Text color="primary.100" textTransform="capitalize">
            {translate("modules.journal.copy")}
          </Text>
        </Button>

        <Menu
          trigger={(triggerProps) => (
            <Button variant="ghost" {...triggerProps}>
              <Text color="primary.100" textTransform="capitalize">
                {translate("modules.journal.edit")}
              </Text>
            </Button>
          )}
        >
          <Menu.Item onPress={() => handleEditPress("EditMap")}>
            <Text color="primary.100" textTransform="capitalize">
              {translate("modules.map.map")}
            </Text>
          </Menu.Item>
          <Menu.Item
            onPress={() => handleEditPress("EditLocation")}
            disabled={!targetRegion?.locations}
          >
            <Text color="primary.100" textTransform="capitalize">
              {translate("modules.map.location")}
            </Text>
          </Menu.Item>
          <Menu.Item onPress={() => handleEditPress("EditDay")}>
            <Text color="primary.100" textTransform="capitalize">
              {translate("modules.journal.day")}
            </Text>
          </Menu.Item>
          <Menu.Item onPress={() => handleEditPress("EditItems")}>
            <Text color="primary.100" textTransform="capitalize">
              {translate("modules.item.item_plural")}
            </Text>
          </Menu.Item>
          <Menu.Item
            textTransform="capitalize"
            onPress={() => handleEditPress("EditNote")}
          >
            <Text color="primary.100" textTransform="capitalize">
              {"Note"}
            </Text>
          </Menu.Item>
        </Menu>
        <Button variant="ghost" onPress={handleDeletePress}>
          <Text color="primary.100" textTransform="capitalize">
            {translate("modules.journal.delete")}
          </Text>
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardText: {
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  journalEntry: {
    paddingTop: spacing.sm - 2,
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  day: {
    fontStyle: "italic",
  },
});
