import { IEntry } from "./types";

export const filterByArrayInclude = <T extends { id: string }>(
  idArr: Array<T["id"]>,
  item: T
): boolean => {
  return idArr.includes(item.id);
};

export const filterItemsDescByQuery = <T extends { desc: string }>(
  query: string,
  item: T
): boolean => {
  return item.desc.toLowerCase().includes(query.toLowerCase());
};

export const createJournalEntryId = (): IEntry["id"] => {
  return String(Date.now());
};

export const createDefaultJournalEntry = (): IEntry => {
  const defaultEntry: IEntry = {
    id: createJournalEntryId(),
    day: 1,
    location: "Draft Dodger's Cabin",
    mapId: "pleasant-valley",
    items: [],
    note: "",
  };

  return defaultEntry;
};
