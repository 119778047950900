import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import React from "react";
import { View } from "react-native";
import { Pressable, Text } from "native-base";
import { AppBar } from "../../components/AppBar";
import { NavigationRow } from "../../components/NavigationRow";
import { useI18n } from "../../hooks/use18n";
import { sharedStyles } from "../../sharedStyles";
import { ITrophyType } from "./types";

export const Trophies: React.FunctionComponent = () => {
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const { translate } = useI18n();
  const trophyTypes: ITrophyType[] = [
    "custom",
    "standard",
    "hidden",
    "feat",
    "challenge",
  ];

  const handlePress = (type: ITrophyType) => {
    navigation.navigate(type);
  };

  return (
    <View style={sharedStyles.flex1}>
      <AppBar title={translate("modules.trophies.trophy_plural")} />
      <View style={sharedStyles.screenContainer}>
        {trophyTypes.map((trophyType) => {
          return (
            <Pressable key={trophyType} onPress={() => handlePress(trophyType)}>
              <NavigationRow>
                <Text textTransform="capitalize">
                  {translate(`modules.trophies.${trophyType}`)}
                </Text>
              </NavigationRow>
            </Pressable>
          );
        })}
      </View>
    </View>
  );
};
