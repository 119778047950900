import { Column, Text } from "native-base";
import React, { useCallback } from "react";
import { AppBar } from "../../components/AppBar";
import { FirstAid as FirstAidType } from "../../generated/graphql";
import { useI18n } from "../../hooks/use18n";
import { ItemCard } from "./components/ItemCard";
import { ScreenContainer } from "./components/ScreenContainer";
import { useFirstAid } from "./hooks/useFirstAid";
import { ItemList } from "./ItemList";

export const FirstAid: React.FunctionComponent = () => {
  const { translate } = useI18n();
  const { data, loading } = useFirstAid();

  const renderItem = useCallback(
    ({ item }: { item: FirstAidType }) => (
      <ItemCard key={item.id} item={item}>
        <Column>
          {item.treats_description ? (
            <>
              <Text fontSize={"md"}>Treats</Text>
              <Text>
                {
                  //@ts-ignore
                  translate(item.treats_description)
                }
              </Text>
            </>
          ) : null}
          {item.effect_description ? (
            <>
              <Text fontSize={"md"}>Effects</Text>
              <Text>
                {
                  //@ts-ignore
                  translate(item.effect_description)
                }
              </Text>
            </>
          ) : null}
        </Column>
      </ItemCard>
    ),
    []
  );
  return (
    <ScreenContainer>
      <AppBar goBack title={translate("modules.item.first-aid")} />
      <ItemList data={data} loading={loading} renderItem={renderItem} />
    </ScreenContainer>
  );
};
