import * as React from "react";
import { ITrophy } from "./modules/Trophies/types";

const throwError = () => {
  throw new Error("Undefined Context");
};

export const CustomTrophyContext = React.createContext<{
  customTrophies: ITrophy[];
  setCustomTrophies: (t: ITrophy[]) => void;
}>({
  customTrophies: [],
  setCustomTrophies: () => throwError(),
});
