import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import {
  Box,
  Column,
  Image,
  Pressable,
  Row,
  SectionList,
  Select,
  Text,
} from "native-base";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppBar } from "../../components/AppBar";
import { NavigationRow } from "../../components/NavigationRow";
import { useI18n } from "../../hooks/use18n";
import { appNBMarginHorizontalProp } from "../../sharedStyles";
import { kebabToTitleCase } from "../../utils/calculations";
import { getUserLocale } from "../../utils/userLocaleSlice";
import { RouteNames } from "./constants";
import { caveData } from "./data/caveData";
import { transitionsData } from "./data/transitionsData";
import { IMapSetting, IRegion } from "./types";
import { useRegions } from "./hooks/useRegions";
import { ScreenContainer } from "../Items/components/ScreenContainer";
import { getLanguageDisplayName } from "@src/utils/getLanguageDisplayName";
interface IProps {
  region: IRegion;
}

export const RegionDetail: React.FunctionComponent<IProps> = (props) => {
  const { translate } = useI18n();
  const userLocale = useSelector(getUserLocale);
  const [mapsLanguage, setMapsLanguage] = useState<"ru" | "en" | string>();
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const regions = useRegions();
  const region = regions.find(
    (targetRegion) => targetRegion.name === props.region.name
  );
  useEffect(() => {
    if (userLocale === "ru") {
      setMapsLanguage("ru");
    } else {
      setMapsLanguage("en");
    }
  }, [userLocale]);

  const regionTransitions = transitionsData.filter((t) =>
    t.regionKeys.includes(props.region.name)
  );
  const regionCaves = caveData.filter((t) =>
    t.regionKeys.includes(props.region.name)
  );

  const renderSectionHeader = useCallback(
    ({ section: { title } }: { section: { title: string } }) => {
      return (
        <Box mx={appNBMarginHorizontalProp} bg="primary.900">
          <Text fontSize="md">
            {kebabToTitleCase(title)}
            {props.region.name === title && mapsLanguage !== "en"
              ? ` (${mapsLanguage?.toUpperCase()})`
              : null}
          </Text>
        </Box>
      );
    },
    [mapsLanguage, props.region.name]
  );

  // wait for map language to be set before trying to render
  if (!mapsLanguage) return null;

  return (
    <ScreenContainer>
      <AppBar goBack title={translate(`modules.map.${props.region.name}`)} />
      {props.region.hasAmmoWorkbench ||
      props.region.hasForge ||
      props.region.difficulty ? (
        <Row mb="2" mr="4" space="2" alignItems="center" flexWrap="wrap">
          {props.region.difficulty ? (
            <Box px="4" rounded="full" background="primary.800">
              <Text textTransform="capitalize">
                {translate(`modules.map.difficulty.${props.region.difficulty}`)}
              </Text>
            </Box>
          ) : null}
          {props.region.hasForge ? (
            <Row
              px="4"
              rounded="full"
              background="primary.800"
              alignItems="center"
            >
              <Text textTransform="capitalize">
                {translate("modules.map.forge")}
              </Text>
            </Row>
          ) : null}
          {props.region.hasAmmoWorkbench ? (
            <Row
              px="4"
              rounded="full"
              background="primary.800"
              alignItems="center"
            >
              <Text textTransform="capitalize">
                {translate("modules.map.ammo-workbench")}
              </Text>
            </Row>
          ) : null}
        </Row>
      ) : null}

      <Column flex="1" mb="3">
        <Column w={["full"]} mb={"1"}>
          <Image
            w={"full"}
            h={["48", "64", "64"]}
            source={{ uri: region?.image }}
            alt={props.region.name}
            rounded="md"
          />
          <Row
            mt="2"
            alignItems={"center"}
            justifyContent={"space-between"}
            mb="2"
          >
            <Text fontSize="lg" textTransform="capitalize">
              {translate("modules.map.map_plural")}
            </Text>
            <Select
              onValueChange={setMapsLanguage}
              selectedValue={mapsLanguage}
              borderColor="white"
              width="125"
            >
              <Select.Item label={getLanguageDisplayName("en")} value="en" />
              <Select.Item label={getLanguageDisplayName("ru")} value="ru" />
            </Select>
          </Row>
        </Column>
        <SectionList
          sections={[
            {
              title: props.region.name,
              //@ts-ignore
              data: props.region.maps[mapsLanguage]
                ? //@ts-ignore
                  Object.keys(props.region.maps[mapsLanguage])
                : props.region.maps["en"],
            },
            ...regionTransitions.map((transition) => ({
              title: transition.name,
              data: Object.keys(transition?.maps["en"] || {}),
            })),
            ...regionCaves.map((regionCave) => ({
              title: regionCave.name,
              data: Object.keys(regionCave?.maps["en"] || {}),
            })),
          ]}
          keyExtractor={(item, index) => item + index}
          renderItem={({ item, section }) => (
            <Box ml={"12"}>
              <Pressable
                onPress={() =>
                  navigation.navigate(RouteNames.Map.name, {
                    type: item,
                    name: section.title,
                    locale: mapsLanguage,
                  })
                }
                w="full"
              >
                <NavigationRow>
                  <Text textTransform={"capitalize"}>
                    {translate(`modules.map.${item as IMapSetting}`)}
                  </Text>
                </NavigationRow>
              </Pressable>
            </Box>
          )}
          renderSectionFooter={() => <Box mb="3" />}
          renderSectionHeader={renderSectionHeader}
        />
      </Column>
    </ScreenContainer>
  );
};
