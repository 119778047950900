import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { RootState } from "../../store";

const deprecatedFavoriteStorageKey = "favorite-region";

export const migrateAsyncStorageFavorite = createAsyncThunk(
  "favoriteRegion/migrateAsyncStorageFavorite",
  async (): Promise<string> => {
    const favoriteRegion = await AsyncStorage.getItem(
      deprecatedFavoriteStorageKey
    );

    AsyncStorage.removeItem(deprecatedFavoriteStorageKey);

    return favoriteRegion || "";
  }
);

interface IState {
  favoriteRegion: string;
}

const initialState: IState = { favoriteRegion: "" };

export const favoriteRegionSlice = createSlice({
  name: "favoriteRegion",
  initialState,
  reducers: {
    clearFavoriteRegion: (state) => {
      state.favoriteRegion = "";
    },
    putFavoriteRegion: (
      state,
      action: PayloadAction<{
        newFavoriteRegion: string;
      }>
    ) => {
      const { newFavoriteRegion } = action.payload;

      state.favoriteRegion = newFavoriteRegion;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      migrateAsyncStorageFavorite.fulfilled,
      (state: IState, action: PayloadAction<string>) => {
        if (action.payload) {
          state.favoriteRegion = action.payload;
        }
      }
    );
  },
});

// Selectors
export function getFavoriteRegion(state: RootState): IState["favoriteRegion"] {
  return state.favoriteRegion.favoriteRegion;
}

export const { clearFavoriteRegion, putFavoriteRegion } =
  favoriteRegionSlice.actions;

export default favoriteRegionSlice.reducer;
