import { maps } from "../../../Constants";
import { ITransition } from "../types";

export const transitionsData: ITransition[] = [
  {
    name: "carter-hydro-dam",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Carter_Hydro_Dam",
    maps: {
      en: {
        "spoiler-free": `${maps.baseUrl}/ckta0mzio1159u5aq1npn4m79`,

        explorer: `${maps.baseUrl}/ckta0n1cv1242u5aq1fdw1tyb`,

        complete: `${maps.baseUrl}/ckta0mzlr1167u5aq4nfnndc8`,

        cairn: `${maps.baseUrl}/ckta0n2h61252u5aqfzu55gj6`,
      },
    },
    regionKeys: ["pleasant-valley", "mystery-lake"],
  },
  {
    name: "old-island-connector",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Old_Island_Connector",
    maps: {
      en: {
        terrain: `${maps.baseUrl}/ckta0mzbl1135u5aqvf28izor`,

        "spoiler-free": `${maps.baseUrl}/ckta0n0wv1222u5aq1aoeayd1`,

        explorer: `${maps.baseUrl}/ckta0mzdw1143u5aqic2ederg`,

        complete: `${maps.baseUrl}/ckta0n15j1232u5aqlyxalg27`,

        cairn: `${maps.baseUrl}/ckta0mzgb1151u5aqdrp2vewg`,
      },
    },
    regionKeys: ["coastal-highway", "desolation-point"],
  },
  {
    name: "the-ravine",
    fandomUrl: "https://thelongdark.fandom.com/wiki/The_Ravine",
    maps: {
      en: {
        complete: `${maps.baseUrl}/ckta0n0b71192u5aq9j9b6xf8`,

        cairn: `${maps.baseUrl}/ckta0n0pn1212u5aqggpdyh4c`,
      },
    },
    regionKeys: ["mystery-lake", "coastal-highway", "bleak-inlet"],
  },
  {
    name: "ml-to-mt-cave",
    maps: {
      en: {
        explorer: `${maps.baseUrl}/ckta0n2ol1262u5aqpl203iuc`,
      },
    },
    regionKeys: ["mystery-lake", "mountain-town"],
  },
  {
    name: "mt-to-hrv-cave",
    maps: {
      en: {
        explorer: `${maps.baseUrl}/ckta0mz461111u5aqlb8kv4zm`,
      },
    },
    regionKeys: ["mountain-town", "hushed-river"],
  },
  {
    name: "pv-to-wr-cave",
    maps: {
      en: {
        explorer: `${maps.baseUrl}/ckta0mzoi1172u5aqe659kfmv`,
      },
    },
    regionKeys: ["pleasant-valley", "winding-river"],
  },
  {
    name: "twm-to-ac-cave",
    maps: {
      en: {
        explorer: `${maps.baseUrl}/ckta0n02w1182u5aqf6z3bfvz`,
      },
    },
    regionKeys: ["timberwolf-mountain", "ash-canyon"],
  },
  {
    name: "no-3-coal-mine",
    maps: {
      en: {
        explorer: `${maps.baseUrl}/ckta0mz921127u5aq9vtonwjg`,
      },
    },
    regionKeys: ["desolation-point"], // not sure where this is supposed to connect
  },
  {
    name: "cinder-hills-coal-mine",
    maps: {
      en: {
        explorer: `${maps.baseUrl}/ckta0mz6j1119u5aqk5s1e17v`,
      },
    },
    regionKeys: ["coastal-highway", "pleasant-valley"],
  },
  {
    name: "fm-to-bi-cave",
    maps: {
      en: {
        explorer: `${maps.baseUrl}/ckta0n0in1202u5aqnwu8z08f`,
      },
    },
    regionKeys: ["forlorn-muskeg", "bleak-inlet"],
  },
  {
    name: "keepers-pass",
    maps: {
      en: {
        "spoiler-free": `${maps.baseUrl}/ckyln0vby01593duwltsrsjse`,
        explorer: `${maps.baseUrl}/ckyln0k5701043duw6zmb1nzj`,
        complete: `${maps.baseUrl}/ckyln029000493duw6tuvlpag`,
        cave: `${maps.baseUrl}/clfs913t13698800umcgn0qcicd`,
      },
    },
    regionKeys: ["blackrock", "pleasant-valley"],
  },
  {
    name: "blackrock-story",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Carter_Hydro_Dam",
    maps: {
      en: {
        "spoiler-free": `${maps.baseUrl}/ckw9eq2o60248hvuwl42c8iub`,
        explorer: `${maps.baseUrl}/ckw9eqh8p0302hvuw7rqz3bzn`,
        complete: `${maps.baseUrl}/ckw9eqx6g0364hvuwgiouzzai`,
      },
    },
    regionKeys: ["blackrock"],
  },
  {
    name: "blackrock-prison",
    maps: {
      en: {
        "spoiler-free": `${maps.baseUrl}/ckw9f2lef0485hvuw5e1whb18`,
        complete: `${maps.baseUrl}/ckw9f2z9i0539hvuwzacg2bff`,
      },
    },
    regionKeys: ["blackrock"],
  },
  {
    name: "steam-tunnels",
    maps: {
      en: {
        "spoiler-free": `${maps.baseUrl}/ckw9f5ix30623hvuw7mnw47fj`,
        explorer: `${maps.baseUrl}/ckw9f5txs0677hvuw997p5a3t`,
        complete: `${maps.baseUrl}/ckw9f63bf0731hvuw8mrubk1p`,
      },
    },
    regionKeys: ["blackrock"],
  },
  {
    name: "mine",
    maps: {
      en: {
        "spoiler-free": `${maps.baseUrl}/ckw9f74u70785hvuwr2majtwg`,
        complete: `${maps.baseUrl}/ckw9f7gdd0839hvuwne26d4hh`,
      },
    },
    regionKeys: ["blackrock"],
  },
  {
    name: "far-range-branch-line",
    maps: {
      en: {
        "spoiler-free": `${maps.baseUrl}/cleppo2bx9333490umcko7hooq9`,
        explorer: `${maps.baseUrl}/cleppnhk69333050umcivuwdoxk`,
      },
    },
    regionKeys: ["broken-railroad", "transfer-pass"],
  },
];
