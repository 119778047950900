import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { JournalEntryList } from "./JournalEntryList";
import { EditDay } from "./EditDay";
import { EditItems } from "./EditItems";
import { EditLocation } from "./EditLocation";
import { EditMap } from "./EditMap";
import { EditNote } from "./EditNote";
import { IParamList } from "./types";
import { JournalList } from "./JournalList";
import { AddJournal } from "./AddJournal";

const JournalStack = createStackNavigator<IParamList>();
const settingStackOptions = { headerShown: false };

export const JournalNavigator: React.FunctionComponent = () => {
  return (
    <JournalStack.Navigator screenOptions={settingStackOptions}>
      <JournalStack.Screen name="default" component={JournalEntryList} />
      <JournalStack.Screen name="JournalList" component={JournalList} />
      <JournalStack.Screen name="EditDay" component={EditDay} />
      <JournalStack.Screen name="EditItems" component={EditItems} />
      <JournalStack.Screen name="EditLocation" component={EditLocation} />
      <JournalStack.Screen name="EditMap" component={EditMap} />
      <JournalStack.Screen name="EditNote" component={EditNote} />
      <JournalStack.Screen name="AddJournal" component={AddJournal} />
    </JournalStack.Navigator>
  );
};
