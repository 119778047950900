import React from "react";
import { ScrollView } from "react-native";
import { Text, Pressable, Box, Column } from "native-base";
import { AppBar } from "../../components/AppBar";
import { NavigationRow } from "../../components/NavigationRow";
import { sharedStyles } from "../../sharedStyles";
import { handleSantanaPress } from "./utils/handleSantanaPress";
import { openURL } from "../../utils/sideEffects";

export const Attributions: React.FunctionComponent = () => {
  const handleBackfiredPress = (): Promise<void> => {
    const url = "https://linktr.ee/backfiredNBApod";
    return openURL(url);
  };

  return (
    <ScrollView style={sharedStyles.flex1}>
      <AppBar goBack title="Attributions" />
      <Column space="4">
        <Pressable onPress={handleSantanaPress}>
          <NavigationRow>
            <Box>
              <Text fontSize="lg">Region Maps</Text>
              <Text pl="4">stmSantana</Text>
            </Box>
          </NavigationRow>
        </Pressable>
        <NavigationRow showChevron={false}>
          <Box>
            <Text fontSize="lg">Polish Translations</Text>
            <Text pl="4">Troll_Fat_Boy</Text>
          </Box>
        </NavigationRow>
        <NavigationRow showChevron={false}>
          <Box>
            <Text fontSize="lg">World Map</Text>
            <Text pl="4">Kai</Text>
          </Box>
        </NavigationRow>
        <Pressable onPress={handleBackfiredPress}>
          <NavigationRow>
            <Box>
              <Text fontSize="lg">iOS Testing Device</Text>
              <Text pl="4">Backfired: An NBA Basketball History Podcast</Text>
            </Box>
          </NavigationRow>
        </Pressable>
      </Column>
    </ScrollView>
  );
};
