import { Clothe, useGetClothesQuery } from "../../../generated/graphql";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { getUserLocale } from "../../../utils/userLocaleSlice";

export function useClothes() {
  const locale = useAppSelector(getUserLocale);
  const results = useGetClothesQuery({ variables: { locale } });

  const formattedData = Object.entries(results.data || {}).reduce(
    (acc, [clotheCategory, values]) => {
      const clotheItems = (values as Array<any>) ?? [];
      return {
        ...acc,
        [clotheCategory]: clotheItems.map((clotheItem: Clothe) => {
          return {
            ...clotheItem,
            name: clotheItem.name_translations
              ? clotheItem.name_translations[0].text
              : "",
          };
        }),
      };
    },
    {}
  );

  return { ...results, data: formattedData };
}
