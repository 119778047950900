import React from "react";
import { convertKgTolbs } from "../utils/convertKgTolbs";
import { Row, Text } from "native-base";
interface DisplayWeightProps {
  displayMode: "pounds" | "kilograms";
  weightInKg: number;
}

export const DisplayWeight: React.FunctionComponent<DisplayWeightProps> = (
  props
) => {
  const { displayMode, weightInKg } = props;

  if (displayMode === "kilograms") {
    return (
      <Row>
        <Text color="white" fontSize={"md"}>
          {weightInKg.toFixed(2)}
        </Text>
        <Text color="primary.100" fontSize="xs" lineHeight={26}>
          {" "}
          kgs
        </Text>
      </Row>
    );
  } else {
    return (
      <Row>
        <Text color="white" fontSize={"md"}>
          {convertKgTolbs(weightInKg).toFixed(2)}
        </Text>
        <Text color="primary.100" fontSize="xs" lineHeight={26}>
          {" "}
          lbs
        </Text>
      </Row>
    );
  }
};
