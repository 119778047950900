import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getUserLocale } from "../utils/userLocaleSlice";
import { useAppSelector } from "./reduxHooks";

export function useI18n() {
  const { i18n, t } = useTranslation();
  const locale = useAppSelector(getUserLocale);

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  return { i18n, translate: t as (...params: any) => string };
}
