import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import fr from "./locales/fr.json";
import pl from "./locales/pl.json";
import ru from "./locales/ru.json";

i18n.use(initReactI18next).init({
  resources: {
    en,
    fr,
    ru,
    pl,
  },
  compatibilityJSON: "v3",
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
