import React, { useCallback } from "react";
import { AppBar } from "../../components/AppBar";
import { Material } from "../../generated/graphql";
import { useI18n } from "../../hooks/use18n";
import { ItemCard } from "./components/ItemCard";
import { ScreenContainer } from "./components/ScreenContainer";
import { useMaterials } from "./hooks/useMaterials";
import { ItemList } from "./ItemList";

export const Materials: React.FunctionComponent = () => {
  const { translate } = useI18n();
  const { data, loading } = useMaterials();

  const renderItem = useCallback(
    ({ item }: { item: Material }) => <ItemCard key={item.id} item={item} />,
    []
  );

  return (
    <ScreenContainer>
      <AppBar goBack title={translate("modules.item.materials")} />
      <ItemList data={data} loading={loading} renderItem={renderItem} />
    </ScreenContainer>
  );
};
