import { canOpenURL, openURL as ExpoLinkingOpenUrl } from "expo-linking";
import { v4 as uuid } from "uuid";

export const openURL = async (url: string) => {
  const possibleToOpenUrl = await canOpenURL(url);
  if (possibleToOpenUrl) {
    ExpoLinkingOpenUrl(url);
  }
};

export const createUniqueId = (): string => uuid();
