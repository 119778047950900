import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import { Box, Row } from "native-base";
import React from "react";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { ScrollView, Text } from "native-base";
import { AppBar } from "../../components/AppBar";
import { SurfaceCard } from "../../components/SurfaceCard";
import { useI18n } from "../../hooks/use18n";
import { sharedStyles, spacing } from "../../sharedStyles";
import { RouteNames } from "../Maps/constants";
import { RegionCard } from "./components/RegionCard";
import { MapIcon, BagIcon, TrophyIcon, BookIcon } from "../../components/Icons";

export const Dashboard: React.FunctionComponent = () => {
  const { translate } = useI18n();
  const iconSize = 48;
  const navigation = useNavigation<DrawerNavigationProp<any>>();

  return (
    <SafeAreaView style={[sharedStyles.flex1]}>
      <AppBar title={translate("modules.dashboard.dashboard")} />
      <ScrollView flex="1">
        <Box mb="2">
          <RegionCard />
        </Box>
        <Row>
          <View style={styles.links}>
            <View style={{ flex: 1, marginBottom: spacing.md }}>
              <SurfaceCard
                onPress={() => navigation.navigate(RouteNames.Maps.name)}
              >
                <MapIcon
                  name={"map"}
                  color={"white"}
                  size={iconSize}
                  style={{ width: iconSize, height: iconSize }}
                />
                <Text style={{ paddingLeft: 4, textTransform: "capitalize" }}>
                  {translate("modules.map.map_plural")}
                </Text>
              </SurfaceCard>
            </View>
            <View style={{ flex: 2 }}>
              <SurfaceCard
                onPress={() => navigation.navigate(RouteNames.Items.name)}
              >
                <BagIcon
                  color={"white"}
                  size={iconSize}
                  style={{ width: iconSize, height: iconSize }}
                />
                <Text style={{ paddingLeft: 6, textTransform: "capitalize" }}>
                  {translate("modules.item.item")}
                </Text>
              </SurfaceCard>
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              flex: 1,
              height: 400,
            }}
          >
            <View style={{ flex: 2, marginBottom: spacing.md }}>
              <SurfaceCard
                onPress={() => navigation.navigate(RouteNames.Trophies.name)}
              >
                <TrophyIcon
                  color={"white"}
                  size={iconSize}
                  style={{ width: iconSize, height: iconSize }}
                />
                <Text style={{ paddingLeft: 1, textTransform: "capitalize" }}>
                  {translate("modules.trophies.trophy_plural")}
                </Text>
              </SurfaceCard>
            </View>
            <View style={sharedStyles.flex1}>
              <SurfaceCard
                onPress={() => navigation.navigate(RouteNames.Journal.name)}
              >
                <BookIcon
                  color={"white"}
                  size={iconSize}
                  style={{ width: iconSize, height: iconSize }}
                />
                <Text style={{ paddingLeft: 2, textTransform: "capitalize" }}>
                  {translate("modules.journal.journal")}
                </Text>
              </SurfaceCard>
            </View>
          </View>
        </Row>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  image: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
  },
  container: {
    paddingHorizontal: spacing.md,
    flex: 1,
  },
  headline: {
    flex: 1,
    marginBottom: spacing.md,
    textTransform: "capitalize",
  },
  links: {
    flexDirection: "column",
    flex: 1,
    height: 400,
    marginRight: spacing.md,
  },
});
