import {
  Box,
  Button,
  Column,
  Row,
  ScrollView,
  Spacer,
  Text,
} from "native-base";
import React, { useCallback, useEffect, useState } from "react";
import { AppBar } from "../../components/AppBar";
import { Clothe } from "../../generated/graphql";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useI18n } from "../../hooks/use18n";
import { DisplayTemperature } from "./components/DisplayTemperature";
import { ItemCard } from "./components/ItemCard";
import { ScreenContainer } from "./components/ScreenContainer";
import { useClothes } from "./hooks/useClothes";
import { ItemList } from "./ItemList";
import { getTemperatureDisplayMode } from "./unitDisplayModeSlice";
import {
  RainyIcon,
  RunIcon,
  ShieldIcon,
  ThermometerIcon,
  WindyIcon,
} from "../../components/Icons";

export const AttributesRow: React.FunctionComponent<Clothe> = (props) => {
  const temperatureDisplayMode = useAppSelector(getTemperatureDisplayMode);

  if (!temperatureDisplayMode) return null;

  return (
    <Column space={"xs"}>
      <Row w={"full"} justifyContent={"space-between"}>
        <Row>
          <ThermometerIcon name="thermometer" color="primary.100" />
          <Text ml="2">Warmth</Text>
        </Row>
        <Column alignItems={"center"}>
          <DisplayTemperature
            temperatureInC={props?.warmth || 0}
            displayMode={temperatureDisplayMode}
          />
        </Column>
      </Row>
      <Row justifyContent={"space-between"}>
        <Row>
          <WindyIcon name="weather-windy" color="primary.100" />
          <Text ml="2">Windproof</Text>
        </Row>
        <Column alignItems={"center"}>
          <DisplayTemperature
            temperatureInC={props?.windproof || 0}
            displayMode={temperatureDisplayMode}
          />
        </Column>
      </Row>
      <Row justifyContent={"space-between"}>
        <Row>
          <RainyIcon name="water" color="primary.100" />
          <Text ml="2">Waterproof</Text>
        </Row>
        <Column alignItems={"center"}>
          <Text>
            {props.waterproof} <Text color="primary.100">%</Text>
          </Text>
        </Column>
      </Row>
      <Row justifyContent={"space-between"}>
        <Row>
          <ShieldIcon size={"md"} color="primary.100" />
          <Text ml="2">Protection</Text>
        </Row>
        <Column alignItems={"center"}>
          <Text>
            {props.protection} <Text color="primary.100">%</Text>
          </Text>
        </Column>
      </Row>
      <Row justifyContent={"space-between"}>
        <Row>
          <RunIcon size={"md"} color="primary.100" />
          <Text ml="2">Mobility</Text>
        </Row>
        <Column alignItems={"center"}>
          <Text>
            {props.mobility} <Text color="primary.100">%</Text>
          </Text>
        </Column>
      </Row>
    </Column>
  );
};

interface ClothingItemProps {
  clothe: Clothe;
}
const ClothingItem: React.FunctionComponent<ClothingItemProps> = (props) => {
  const { clothe } = props;

  return (
    <ItemCard item={clothe}>
      <Box mt="2">
        <AttributesRow {...clothe} />
      </Box>
    </ItemCard>
  );
};

export const Clothing: React.FunctionComponent = () => {
  const { translate } = useI18n();
  const { data, loading } = useClothes();

  const [selectedClotheType, setSelectedClotheType] = useState("");

  useEffect(() => {
    if (data && !selectedClotheType) {
      const firstClothingCategory = Object.keys(data)[0];
      setSelectedClotheType(firstClothingCategory);
    }
  }, [data]);

  const renderItem = useCallback(({ item: clothe }: { item: Clothe }) => {
    return <ClothingItem key={clothe.id} clothe={clothe} />;
  }, []);

  return (
    <ScreenContainer>
      <AppBar goBack title={translate("modules.item.clothing")} />
      <Box h="12">
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          {Object.keys(data || {}).map((clotheType) => {
            const selected = selectedClotheType === clotheType;
            return (
              <Button
                key={String(clotheType)}
                onPress={() => setSelectedClotheType(String(clotheType))}
                rounded="full"
                bg={selected ? "transparent" : undefined}
                borderColor={selected ? "primary.300" : undefined}
                borderWidth={selected ? 1 : undefined}
                mr={4}
              >
                <Text
                  textTransform={"capitalize"}
                  onPress={() => setSelectedClotheType(String(clotheType))}
                >
                  {/* @ts-ignore */}
                  {translate(`modules.item.${clotheType}`)}
                </Text>
              </Button>
            );
          })}
        </ScrollView>
      </Box>
      <Spacer size="4" />
      <ItemList
        // @ts-ignore
        data={data ? data[selectedClotheType] : []}
        loading={loading}
        renderItem={renderItem}
      />
    </ScreenContainer>
  );
};
