export function capitalizeWordsInString(str: string): string {
  const words = str.split(" ");
  const capitalizedWords = words.map((word) => {
    const capitalizedFirstLetter = word[0].toUpperCase();
    const restWord = word.slice(1, word.length);

    return capitalizedFirstLetter + restWord;
  });

  return capitalizedWords.join(" ");
}
