import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IJournal } from "./types";

export const currentJournalIdSlice = createSlice({
  name: "currentJournalId",
  initialState: "default",
  reducers: {
    putJournalId: (_, action: PayloadAction<IJournal["id"]>) => {
      return action.payload;
    },
  },
});

export const { putJournalId } = currentJournalIdSlice.actions;

export default currentJournalIdSlice.reducer;
