export const challengeData = [
  {
    id: "hopeless-rescue",
  },

  {
    id: "the-hunted-part-one",
  },

  {
    id: "the-hunted-part-two",
  },
  {
    id: "whiteout",
  },

  {
    id: "nomad",
  },
  {
    id: "archivist",
  },
  {
    id: "as-the-dead-sleep",
  },
  {
    id: "4DON Twilight Badge (2018)",
  },
  {
    id: "4DON Midnight Badge (2018)",
  },
  {
    id: "4DON Twilight Badge (2019)",
  },
  {
    id: "4DON Midnight Badge (2019)",
  },
  {
    id: "Winter's Embrace Canadian Feast Badge (2020)",
  },
  {
    id: "Winter's Embrace Winter in July Badge (2020)",
  },
  {
    id: "escape-the-darkwalker",
  },
  {
    id: "Escape the Darkwalker -- Deadly Dreamer Badge",
  },
];
