export const colors = {
  primary: {
    50: "#DCE7F3",
    100: "#CBDBED",
    200: "#A9C3E1",
    300: "#87ACD6",
    400: "#6494CA",
    500: "#427DBE",
    600: "#36669D",
    700: "#2A507B",
    800: "#1E3A59",
    900: "#0B233E",
  },
};

export const backgroundColor = colors.primary[900];
export const hoverColor = colors.primary[800];
