import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import {
  Box,
  Center,
  Column,
  Pressable,
  QuestionOutlineIcon,
  Text,
} from "native-base";
import React from "react";
import { ScrollView } from "react-native";
import { hoverColor } from "../../colors";
import { AppBar } from "../../components/AppBar";
import {
  FirstAidIcon,
  FoodIcon,
  LeafIcon,
  ShirtIcon,
  ToolIcon,
} from "../../components/Icons";
import { SurfaceCard } from "../../components/SurfaceCard";
import { useI18n } from "../../hooks/use18n";
import { openItemIssueUrl } from "./utils/openItemIssueUrl";

export const ItemCategories: React.FunctionComponent = () => {
  const { translate } = useI18n();
  const iconSize = 12;
  const navigation = useNavigation<DrawerNavigationProp<any>>();

  type Category = {
    Icon: React.ReactNode;
    itemPage: string;
    label: string;
  };

  const itemCategories: Array<Category> = [
    {
      Icon: <FirstAidIcon size={iconSize} color="white" />,
      itemPage: "firstAid",
      label: translate("modules.item.first-aid"),
    },
    {
      Icon: <ShirtIcon size={iconSize} color="white" />,
      itemPage: "clothing",
      label: translate("modules.item.clothing"),
    },
    {
      Icon: <FoodIcon size={iconSize} color="white" />,
      itemPage: "food",
      label: translate("modules.item.food"),
    },
    {
      Icon: <ToolIcon size={iconSize} color="white" />,
      itemPage: "tools",
      label: translate("modules.item.tools"),
    },
    {
      Icon: <LeafIcon size={iconSize} color="white" />,
      itemPage: "materials",
      label: translate("modules.item.materials"),
    },
  ];

  return (
    <>
      <AppBar title={translate("modules.item.item_plural")} />
      <ScrollView>
        <Column space={"4"}>
          {itemCategories.map((itemCategory) => {
            return (
              <Box key={itemCategory.label}>
                <SurfaceCard
                  onPress={() => navigation.navigate(itemCategory.itemPage)}
                >
                  {itemCategory.Icon}
                  {/* <color={"white"} size={iconSize} /> */}
                  <Text textTransform={"capitalize"}>{itemCategory.label}</Text>
                </SurfaceCard>
              </Box>
            );
          })}
        </Column>
        <Pressable
          onPress={openItemIssueUrl}
          rounded="md"
          _hover={{
            bg: hoverColor,
          }}
          borderWidth={1}
          borderColor="primary.100"
          mt="4"
          p={4}
        >
          <Center>
            <QuestionOutlineIcon name="help" size="3xl" color={"white"} />

            <Text mt={"2"} textAlign="center" textTransform={"capitalize"}>
              {translate("modules.item.missing-something")}
            </Text>
          </Center>
        </Pressable>
      </ScrollView>
    </>
  );
};
