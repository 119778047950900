import { RouteProp } from "@react-navigation/native";
import {
  Box,
  Button,
  Input,
  Radio,
  ScrollView,
  Spacer,
  Text,
} from "native-base";
import React, { useCallback, useEffect, useState } from "react";
import { AppBar } from "../../components/AppBar";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useI18n } from "../../hooks/use18n";
import { usePrevious } from "../../hooks/usePrevious";
import { IRegion, IRegionNames } from "../../modules/Maps/types";
import { kebabToTitleCase } from "../../utils/calculations";
import { useRegions } from "../Maps/hooks/useRegions";
import { caveData } from "./../../modules/Maps/data/caveData";
import { transitionsData } from "./../../modules/Maps/data/transitionsData";
import { useJournalEntry } from "./hooks/useJournalEntry";
import { putJournalEntry } from "./journalSlice";
import { IParamList } from "./types";

type EditScreenRouteProp = RouteProp<IParamList, "EditMap">;

interface IProps {
  route: EditScreenRouteProp;
}
type MapType = "regions" | "transitions" | "caves";

export const EditMap: React.FunctionComponent<IProps> = (props) => {
  const { translate } = useI18n();
  const journalId = useAppSelector((state) => state.currentJournalId);
  const entryId = props.route.params.entryId;
  const { targetJournalEntry } = useJournalEntry(entryId);
  const regions = useRegions();
  const dispatch = useAppDispatch();
  // @TODO Select correct chip for the passed in map
  const [selectedMapType, setSelectedMapType] = useState<MapType>("regions");
  const [filterTerm, setFilterTerm] = useState("");
  const [selectedMap, setSelectedMap] = useState(
    targetJournalEntry?.mapId || "bleak-inlet"
  );
  const prevSelectedMap = usePrevious(selectedMap);

  useEffect(() => {
    if (prevSelectedMap !== selectedMap) {
      dispatch(
        putJournalEntry({
          journalId,
          entry: {
            id: entryId,
            mapId: selectedMap,
            location: "",
          },
        })
      );
    }
  }, [selectedMap]);

  const handleMapTypePress = useCallback((newMapType: MapType) => {
    setSelectedMapType(newMapType);
  }, []);

  const handleNewFilterTerm = useCallback((newFilterTerm: string) => {
    setFilterTerm(newFilterTerm);
  }, []);

  const data =
    selectedMapType === "regions"
      ? regions
      : selectedMapType === "caves"
      ? caveData
      : transitionsData;

  const sortedData = [...data].sort((a, b) => (a.name < b.name ? -1 : 1));

  return (
    <Box>
      <AppBar goBack title={translate("modules.map.map")} />
      <Text fontSize="lg">Map Types</Text>
      <Spacer size="2" />
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <Button
          rounded="lg"
          variant={selectedMapType === "regions" ? "solid" : "outline"}
          onPress={() => handleMapTypePress("regions")}
          mr="4"
        >
          <Text textTransform="capitalize">
            {translate("modules.map.region_plural")}
          </Text>
        </Button>
        <Button
          rounded="lg"
          variant={selectedMapType === "transitions" ? "solid" : "outline"}
          onPress={() => handleMapTypePress("transitions")}
          mr="4"
        >
          <Text textTransform="capitalize">
            {translate("modules.map.transitionZones")}
          </Text>
        </Button>
        <Button
          rounded="lg"
          variant={selectedMapType === "caves" ? "solid" : "outline"}
          onPress={() => handleMapTypePress("caves")}
        >
          <Text textTransform="capitalize">
            {translate("modules.map.cave_plural")}
          </Text>
        </Button>
      </ScrollView>
      <Spacer size="4" />
      <Input
        value={filterTerm}
        onChangeText={handleNewFilterTerm}
        placeholder={"Search"}
      />
      <Spacer size="4" />
      <ScrollView>
        <Radio.Group
          onChange={(newValue) => setSelectedMap(newValue as IRegionNames)}
          value={selectedMap}
          name="list"
          space="4"
        >
          {sortedData

            // @ts-ignore
            .filter((region: IRegion) => {
              return region.name
                .toLowerCase()
                .includes(filterTerm.toLowerCase());
            })
            // @ts-ignore
            .map((region: IRegion) => {
              return (
                <Radio
                  key={region.name}
                  textTransform="capitalize"
                  value={region.name}
                >
                  {selectedMapType === "regions"
                    ? translate(`modules.map.${region.name}`)
                    : kebabToTitleCase(region.name)}
                </Radio>
              );
            })}
        </Radio.Group>
      </ScrollView>
    </Box>
  );
};
