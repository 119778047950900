import React from "react";
import { Row, Text } from "native-base";
import { convertCtoF } from "../utils/convertCtoF";

interface DisplayTemperatureProps {
  displayMode: "celsius" | "fahrenheit";
  temperatureInC: number;
}

export const DisplayTemperature: React.FunctionComponent<
  DisplayTemperatureProps
> = (props) => {
  const { displayMode, temperatureInC } = props;

  if (displayMode === "celsius") {
    return (
      <Row>
        <Text color="white" fontSize={"sm"}>
          + {temperatureInC}
        </Text>
        <Text color="primary.100" fontSize="xs" lineHeight={24}>
          {" "}
          °C
        </Text>
      </Row>
    );
  } else {
    return (
      <Row>
        <Text color="white" fontSize={"sm"}>
          {"+ " + (convertCtoF(temperatureInC) - 32).toFixed(1)}
        </Text>
        <Text color="primary.100" fontSize="xs" lineHeight={24}>
          {" "}
          °F
        </Text>
      </Row>
    );
  }
};
