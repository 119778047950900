import { Text } from "native-base";
import React from "react";
import { View, ViewStyle } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { AppBar } from "../../components/AppBar";
import { useConstants } from "../../hooks/useConstants";
import { sharedStyles, spacing } from "../../sharedStyles";

export const ChangeLog: React.FunctionComponent = () => {
  const { manifest } = useConstants();
  const isCurrentVersion = (version: string) => version === manifest?.version;

  const changes = [
    {
      version: "1.6.0",
      features: ["Add Russian Maps"],
    },
    {
      version: "1.5.2",
      features: ["Style Updates"],
    },
    {
      version: "1.5.0",
      features: ["Use database for item info"],
    },
    {
      version: "1.4.3",
      features: [
        "Style Changes",
        "Create Web App at https://app.extendednightcompanion.com",
      ],
    },
    {
      version: "1.4.1",
      features: ["Remove Ad Banner"],
    },
    {
      version: "1.4.0",
      features: ["Allows pins to be added to maps"],
    },
    {
      version: "1.3.3",
      features: [
        "Adds Polish Language (Thank You Troll_Fat_Boy)",
        "Adds Blackrock survival map",
        "Fixes translations on tools screens",
      ],
    },
    {
      version: "1.3.2",
      features: ["Adds Russian Language"],
    },
    {
      version: "1.3.1",
      features: ["Adds Blackrock map"],
    },
    {
      version: "1.3.0",
      features: [
        "Adds Clothing and Food Sorting",
        "Adds Landscape Mode",
        "Adds Pound Display",
        "Adds Fahrenheit Display",
      ],
    },
    {
      version: "1.2.0",
      features: ["Adds world map", "Adds Journal"],
    },
    {
      version: "1.1.0",
      features: ["Adds basic item info"],
    },
    {
      version: "1.0.11",
      features: [
        "Adds Transition maps",
        "Adds Cave maps",
        "Adds Cairn maps",
        "Adds more trophies",
      ],
    },
    {
      version: "1.0.10",
      features: [
        "Adds available colored maps",
        "Adds custom trophies",
        "Adds 'Completed' and 'Todo' \n   Tabs to Trophies",
        "Adds Change Log Screen",
        "Fixes Minor bugs",
      ],
    },
    {
      version: "1.0.8",
      features: ["Adds Maps", "Adds Trophy", "Adds Settings"],
    },
  ];

  const VERSION_TITLE: ViewStyle = {
    flexDirection: "row",
    alignItems: "center",
  };

  const CURRENT_CAPTION: ViewStyle = {
    paddingLeft: spacing.sm,
  };

  const CHANGE: ViewStyle = {
    paddingLeft: spacing.md,
  };

  return (
    <View style={sharedStyles.flex1}>
      <AppBar goBack title="Change Log" />
      <ScrollView style={sharedStyles.screenContainer}>
        {changes.map((change) => (
          <View key={change.version}>
            <View style={VERSION_TITLE}>
              <Text
                color={
                  isCurrentVersion(change.version) ? "orange.500" : "white"
                }
              >
                {change.version}
              </Text>
              {isCurrentVersion(change.version) && (
                <Text style={CURRENT_CAPTION}>Current</Text>
              )}
            </View>
            {change.features.map((description) => (
              <Text
                selectable
                style={CHANGE}
                key={change.version + description}
              >
                - {description}
              </Text>
            ))}
          </View>
        ))}
      </ScrollView>
    </View>
  );
};
