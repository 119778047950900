import {
  compose,
  filter,
  includes,
  prop,
  reverse,
  sortBy,
  toLower,
} from "ramda";
export function kebabToTitleCase(str = ""): string {
  return str
    .replace("-", " ")
    .replace("-", " ")
    .split(" ")
    .map((w) => {
      const [firstLetter, ...rest] = Array.from(w);
      return [firstLetter?.toUpperCase(), ...rest].join("");
    })
    .join(" ");
}

export const filterListByString = (query: string) => filter(includes(query));
export const sortByDescProp = sortBy(compose(toLower, prop("desc")));
export const sortByPropAscending = (property: string): any =>
  sortBy(prop(property));
export const sortByPropDescending = (property: string): any => {
  const sorter = sortByPropAscending(property);

  return (list: unknown[]) => {
    const sortedList = sorter(list);

    return reverse(sortedList);
  };
};
