import { useGetToolsQuery } from "../../../generated/graphql";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { getUserLocale } from "../../../utils/userLocaleSlice";

export function useTools() {
  const locale = useAppSelector(getUserLocale);
  const results = useGetToolsQuery({ variables: { locale } });
  const toolsData = results?.data?.tools ?? [];
  const formattedData = toolsData.map((toolItem) => {
    return {
      ...toolItem,
      description: toolItem.description_translations?.[0]
        ? toolItem.description_translations[0].text
        : "",
      name: toolItem.name_translations?.[0]
        ? toolItem.name_translations[0].text
        : "",
    };
  });

  return { ...results, data: formattedData };
}
