import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import {
  AddIcon,
  Box,
  FlatList,
  Menu,
  Pressable,
  Text,
  ThreeDotsIcon,
} from "native-base";
import { last } from "ramda";
import React, { useCallback } from "react";
import { ListRenderItem, View } from "react-native";
import { AppBar } from "../../components/AppBar";
import { Line } from "../../components/Line";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useI18n } from "../../hooks/use18n";
import { sharedStyles } from "../../sharedStyles";
import { capitalizeWordsInString } from "../../utils/capitalizeWordsInString";
import { EntryCard } from "./components/EntryCard";
import {
  addJournalEntry,
  deleteAllJournalEntries,
  deleteJournalEntry,
} from "./journalSlice";
import { IEntry } from "./types";
import { createDefaultJournalEntry, createJournalEntryId } from "./utils";
import { alert } from "../../utils/Alert";

export const JournalEntryList: React.FunctionComponent = () => {
  const { translate } = useI18n();
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const dispatch = useAppDispatch();
  const journalId = useAppSelector((state) => state.currentJournalId);
  const journal = useAppSelector((state) => state.journals[journalId] || {});

  const renderItem: ListRenderItem<IEntry> = useCallback(
    ({ index, item }) => (
      <EntryCard
        key={index}
        journalEntry={item}
        copy={handleCopy}
        delete={(entry) => {
          dispatch(deleteJournalEntry({ journalId, entry }));
        }}
      />
    ),
    [journal.entries]
  );

  const handleCopy = (entry: IEntry) => {
    const latestEntry: IEntry | undefined = journal?.entries[0];

    dispatch(
      addJournalEntry({
        journalId,
        entry: {
          ...entry,
          day: latestEntry.day + 1,
          id: createJournalEntryId(),
        },
      })
    );
  };

  const handleAddPress = useCallback(() => {
    const latestEntry: IEntry | undefined = last(journal?.entries);
    if (latestEntry) {
      /**
       * Increment the day and keep location info
       * Clear everything else
       */
      const newEntry: IEntry = {
        ...latestEntry,
        id: createJournalEntryId(),
        day: latestEntry.day + 1,
        items: [],
        note: "",
      };
      dispatch(addJournalEntry({ journalId, entry: newEntry }));
    } else {
      dispatch(
        addJournalEntry({ journalId, entry: createDefaultJournalEntry() })
      );
    }
  }, [journal.entries]);

  const renderEmpty = useCallback(
    () => (
      <View style={[sharedStyles.center]}>
        <Text fontSize="lg" textTransform="capitalize">
          {translate("modules.journal.no-journals-entries")}
        </Text>
      </View>
    ),
    []
  );

  const clearAllJournals = useCallback(() => {
    alert(
      capitalizeWordsInString(translate("modules.journal.delete-all-journals")),
      capitalizeWordsInString(
        translate("modules.journal.delete-all-journals-desc", {
          count: journal.entries.length,
        })
      ),
      // `Are you sure you want to \ndelete all ${journal.entries.length} entires?`,
      [
        {
          text: capitalizeWordsInString(translate("common.cancel")),
          style: "cancel",
        },
        {
          text: capitalizeWordsInString(translate("modules.journal.delete")),
          style: "destructive",
          onPress: () => {
            dispatch(deleteAllJournalEntries({ journalId }));
          },
        },
      ]
    );
  }, [journal.entries]);

  const sortedJournals = [...(journal?.entries || [])].sort((a, b) => {
    return b.day - a.day;
  });

  const OverFlowMenu = () => {
    return (
      <Menu
        trigger={(triggerProps) => (
          <Pressable
            {...triggerProps}
            w="full"
            h="full"
            justifyContent="center"
            alignItems="center"
          >
            <ThreeDotsIcon />
          </Pressable>
        )}
      >
        <Menu.Item onPress={handleChangeJournalsPress}>
          <Text textTransform="capitalize" color="primary.100">
            {translate("modules.journal.change-journal")}
          </Text>
        </Menu.Item>
        <Menu.Item onPress={clearAllJournals}>
          <Text color="primary.100" textTransform="capitalize">
            {translate("modules.journal.delete-all-journals")}
          </Text>
        </Menu.Item>
      </Menu>
    );
  };

  const handleChangeJournalsPress = useCallback(() => {
    navigation.navigate("JournalList");
  }, []);
  return (
    <Box>
      <AppBar
        title={`${translate("modules.journal.journal")}`}
        actions={[
          { icon: AddIcon, onPress: handleAddPress },
          { icon: OverFlowMenu },
        ]}
      />

      <FlatList
        ListHeaderComponent={<Text fontSize="xl">{journal.name}</Text>}
        ItemSeparatorComponent={Line}
        stickyHeaderIndices={[0]}
        data={sortedJournals || []}
        key={"id"}
        renderItem={renderItem}
        contentContainerStyle={
          journal.entries.length === 0 && sharedStyles.flex1
        }
        ListEmptyComponent={renderEmpty}
      />
    </Box>
  );
};
