// Cairn Maps: https://steamcommunity.com/sharedfiles/filedetails/?l=polish&id=937442672&fbclid=IwAR2jKI9NjX_b5KKb5IaOrA8asMXZOwn7J--OPtAm-mu0Tpb2cATwSdXaVgk

import { maps } from "../../../Constants";
import { RegionDifficultyEnum } from "../constants";
import { IRegion } from "../types";

export const regions: IRegion[] = [
  {
    difficulty: RegionDifficultyEnum.ADVANCED,
    hasAmmoWorkbench: false,
    hasForge: false,
    name: "ash-canyon",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Ash_Canyon",
    image:
      "https://res.cloudinary.com/dv5szgigi/image/upload/c_scale,q_auto,w_381/v1661899689/extended-night-companion/region-covers/ash-canyon.webp",
    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt6b1fph05821s33oohdthxe`,
        explorer: `${maps.baseUrl}/ckt6b1f4o03681s33co6ythy5`,
        routes: `${maps.baseUrl}/ckt6b1fa503951s33ylpqn9h9`,
        ["spoiler-free"]: `${maps.baseUrl}/ckt6b1fph05831s33maewbfo1`,
        terrain: `${maps.baseUrl}/ckt6b1f4l03661s33mcngsqdo`,
      },
      ru: {
        complete: `${maps.baseUrl}/clb7zqkmf1459260q44cmld6pun`,
      },
    },
    locations: [
      "Angler's Den",
      "Bitter Marsh",
      "Broad Falls",
      "Climber's Cave",
      "Fire Overlook",
      "Foreman's Retreat",
      "Gold Mine",
      "High Meadow",
      "Homesteader's Respite",
      "Long Falls",
      "Pillar's Footrest",
      "Miner's Folly",
      "Mining Camp",
      "Narrow Falls Cave",
      "Runaway Bridge",
      "Shattered Cove",
      "Stone Shelf Cave",
      "Wolf's Jaw Overlook",
    ],
  },
  {
    difficulty: RegionDifficultyEnum.ADVANCED,
    hasAmmoWorkbench: true,
    hasForge: false,
    name: "bleak-inlet",
    image:
      "https://res.cloudinary.com/dv5szgigi/image/upload/c_scale,q_auto,w_381/v1661899687/extended-night-companion/region-covers/bleak-inlet.webp",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Bleak_Inlet",

    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt6b1fc104171s336720ti8r`,
        explorer: `${maps.baseUrl}/ckt6b1fc104151s3312x2ene3`,
        ["spoiler-free"]: `${maps.baseUrl}/ckt6b1fgz04471s33zvbqwzcz`,
        terrain: `${maps.baseUrl}/ckt6b1fa503971s332jmsl90r`,
      },
      ru: {
        complete: `${maps.baseUrl}/clb81z37o1479880q44b7xxe64u`,
      },
    },
    locations: [
      "Last Resort Cannery",
      "Fallen Lighthouse",
      "Washed out trailers",
      "Cannery Worker Residences",
      "Cannery Pier",
      "Long Bridge",
      "Pensive Lookout",
      "Echo One Radio Tower",
      "Cabin",
      "Frozen Delta",
      "Trailers west of the Cannery",
      "Ravine's End Waterfall",
      "Summit at Ravine's End",
      "Coastal Highway Terminus",
      "Coast Mountain Road",
      "Secret Mountain Path",
      "Raven Crossing",
      "Lower Raven Falls",
      "Landslide",
    ],
  },
  {
    difficulty: RegionDifficultyEnum.INTERMEDIATE,
    hasAmmoWorkbench: true,
    hasForge: false,
    name: "blackrock",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Blackrock",
    image:
      "https://res.cloudinary.com/dv5szgigi/image/upload/c_scale,q_auto,w_381/v1661899687/extended-night-companion/region-covers/blackrock.webp",

    maps: {
      en: {
        "spoiler-free": `${maps.baseUrl}/ckx3deew805728euw1m3jcuog`,
        explorer: `${maps.baseUrl}/ckx3ddrhy04978euwlu4fhbqq`,
        complete: `${maps.baseUrl}/ckx3dd88704218euwrq8tdvtq`,
      },
      ru: {
        complete: `${maps.baseUrl}/claff8q71244630q44sjfhujde`,
      },
    },
    locations: [],
  },
  {
    difficulty: RegionDifficultyEnum.INTERMEDIATE,
    hasAmmoWorkbench: false,
    hasForge: true,
    name: "broken-railroad",
    image:
      "https://res.cloudinary.com/dv5szgigi/image/upload/c_scale,q_auto,w_381/v1661899686/extended-night-companion/region-covers/broken-railroad.webp",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Broken_Railroad",

    maps: {
      en: {
        cairn: `${maps.baseUrl}/ckt6b1fte06901s33thtibaj8`,
        complete: `${maps.baseUrl}/ckt6b1fw807721s33l7qs5axi`,
      },
      ru: {
        complete: `${maps.baseUrl}/clb81uq3u1475220q44t93fmjzo`,
      },
    },
    locations: [
      "Broken Bridge",
      "Hunter's Blind",
      "Hunting Lodge",
      "Landslide",
      "Maintenance Shed",
      "Maintenance Yard",
      "Locomotive Engine",
      "Rickety Foot Bridge",
      "Tracker",
    ],
  },
  {
    difficulty: RegionDifficultyEnum.INTERMEDIATE,
    hasAmmoWorkbench: false,
    hasForge: false,
    name: "coastal-highway",
    image:
      "https://res.cloudinary.com/dv5szgigi/image/upload/c_scale,q_auto,w_381/v1661899686/extended-night-companion/region-covers/coastal-highway.webp",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Coastal_Highway",

    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt6b1fti07111s337n5d1uc6`,
        explorer: `${maps.baseUrl}/ckt6b1fw607701s33197t9b5h`,
        ["spoiler-free"]: `${maps.baseUrl}/ckt6b1fz308231s33216ba3ll`,
        cairn: `${maps.baseUrl}/ckt6b1ftb06771s33qpz1j6le`,
      },
      ru: {
        complete: `${maps.baseUrl}/clb801edu1464970q44lkoto6cx`,
      },
    },
    locations: [
      "Abandoned Lookout",
      "Bear Creek Campground",
      "Cinder Hills Coal Mine",
      "Coastal Townsite",
      "Commuter's Lament",
      "Fishing Camp",
      "Jackrabbit Island",
      "Log Sort",
      "Misanthrope's Homestead",
      "Quonset Garage",
      "Rabbit Grove",
      "Rockfall",
      "Silent Clearing",
      "The Tooth",
      "Train Unloading",
      "Waterfront Cottages",
    ],
  },
  {
    difficulty: RegionDifficultyEnum.INTERMEDIATE,
    hasAmmoWorkbench: false,
    hasForge: true,
    name: "desolation-point",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Desolation_Point",
    image:
      "https://res.cloudinary.com/dv5szgigi/image/upload/c_scale,q_auto,w_381/v1661899686/extended-night-companion/region-covers/desolation-point.webp",

    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt6b1fr506591s33pkgmzlnz`,
        explorer: `${maps.baseUrl}/ckt6b1fte06861s334jngeywl`,
        ["spoiler-free"]: `${maps.baseUrl}/ckt6b1fr606611s3381amuz98`,
        cairn: `${maps.baseUrl}/ckt6b1fwk07951s33uote26fb`,
      },
      ru: {
        complete: `${maps.baseUrl}/clb81shgo1471850q440u6kaoo8`,
      },
    },
    locations: [
      "Hibernia Processing",
      "Lonely Lighthouse",
      "The Riken",
      "Blocked Highway",
      "Waterfall (Desolation Point)",
      "Stone Church",
      "Broken Bridge (Desolation Point location)",
      "Little Island",
      "Matt's Truck",
      "Katie's Secluded Corner",
    ],
  },
  {
    difficulty: RegionDifficultyEnum.ADVANCED,
    hasAmmoWorkbench: false,
    hasForge: true,
    name: "forlorn-muskeg",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Forlorn_Muskeg",
    image:
      "https://res.cloudinary.com/dv5szgigi/image/upload/c_scale,q_auto,w_381/v1661899686/extended-night-companion/region-covers/forlorn-muskeg.webp",

    maps: {
      en: {
        explorer: `${maps.baseUrl}/ckt6b1ftc06791s337va50qwx`,
        cairn: `${maps.baseUrl}/ckt6b1fph05861s33zloqbzqb`,
      },
      ru: {
        complete: `${maps.baseUrl}/clb8081hj1468430q449hqrbjyc`,
      },
    },
    locations: [
      "Shortwave Tower (North)",
      "Bunkhouses",
      "Poacher's Camp",
      "Muskeg Overlook",
      "Old Spence Family Homestead",
      "Hat Creek",
      "Marsh Ridge",
      "Waterfall",
      "Cave",
      "High Blind",
      "Abandoned Camp",
      "Low Blind",
    ],
  },
  {
    difficulty: RegionDifficultyEnum.ADVANCED,
    hasAmmoWorkbench: false,
    hasForge: false,
    name: "forsaken-airfield",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Forsaken_Airfield",
    image:
      "https://db3rv8e6wd0zt.cloudfront.net/83cf0d1e-6160-49ba-8e07-48314631aa40.webp",

    maps: {
      en: {
        explorer: `${maps.baseUrl}/cleppb1o99329370umclkr6oyez`,
        ["spoiler-free"]: `${maps.baseUrl}/cleppbtov9329890umcuhbm4ina`,
      },
      ru: {
        explorer: `${maps.baseUrl}/clepq02g89335250umcpe7eat4r`,
      },
    },
    locations: [
      "Archie's Crawl Space",
      "Main Hangar",
      "Island Cottage",
      "Cove",
      "Brittle Cave",
      "Control Tower",
      "Final Approach",
      "Surveyor's Memory",
      "Cargomaster's Trailer",
      "Whealy's Turn",
      "Junker's Paddock",
      "Terminal",
      "Shoulder Lake",
      "Drift Island",
      "Mindful Cabin",
      "Justy's Hovel",
      "Fallow Dugout",
      "Frost Cave",
      "Spotter's Blind",
      "Sudden Washout",
      "Stony Passage",
      "Grip's Cave",
    ],
  },
  {
    difficulty: RegionDifficultyEnum.ADVANCED,
    hasAmmoWorkbench: false,
    hasForge: false,
    name: "hushed-river",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Hushed_River_Valley",
    image:
      "https://res.cloudinary.com/dv5szgigi/image/upload/c_scale,q_auto,w_381/v1661899686/extended-night-companion/region-covers/hushed-river.webp",

    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt6b1fwd07821s3395qtwpfu`,
        explorer: `${maps.baseUrl}/ckt6b1fqp06291s33oddbdcrm`,
        ["spoiler-free"]: `${maps.baseUrl}/ckt6b1fqv06541s339jn1q9eq`,
      },
      ru: {
        complete: `${maps.baseUrl}/clb81xnov1478680q44dx64qznd`,
      },
    },
    locations: [
      "Banner Falls",
      "Cloudtop Falls",
      "Hushed River",
      "Lake Cave",
      "Landslide",
      "Little Bear and Cub Falls",
      "Lonely Cave",
      "Long Shot Falls",
      "Mammoth Falls",
      "Many Falls Vista",
      "Monolith Lake",
      "Moose Overlook",
      "Mysterious Signal Fire",
      "Offset Falls",
      "Peak Cave",
      "Pensive Vista",
      "Quarreling Falls",
      "Reclusive Falls",
      "Stairsteps Lake",
      "Twin Sisters Falls",
      "Valley Cave",
      "Valley View Point",
      "Watchful Falls",
    ],
  },
  {
    difficulty: RegionDifficultyEnum.BEGINNER,
    hasAmmoWorkbench: false,
    hasForge: false,
    name: "mountain-town",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Mountain_Town",
    image:
      "https://res.cloudinary.com/dv5szgigi/image/upload/c_scale,q_auto,w_381/v1661899687/extended-night-companion/region-covers/mountain-town.webp",

    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt6b1fr706631s339jq4ytc5`,
        ["spoiler-free"]: `${maps.baseUrl}/ckt6b1fpc05491s33qxv4z3qo`,
      },
      ru: {
        complete: `${maps.baseUrl}/clb81wjnr1478240q44wauf9sy1`,
      },
    },
    locations: [
      "Crashed Prison Transport Bus",
      "Hermit's Cabin",
      "Orca Gas Station",
      "Paradise Meadows Farm",
      "Spruce Falls Bridge",
      "St.Christopher's Church",
      "Graveyard",
      "Milton Park",
      "Picnic Area",
      "Radio Tower",
      "Rocky Refuge",
      "Mackenzie's Crashed Plane",
      "Milton",
      "Bank Manager's House",
      "Milton Credit Union",
      "Milton House",
      "Milton Post Office",
      "Old Schoolhouse",
      "Wood Lot",
      "Trailer",
      "The Arch",
    ],
  },
  {
    difficulty: RegionDifficultyEnum.BEGINNER,
    hasAmmoWorkbench: false,
    hasForge: false,
    name: "mystery-lake",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Mystery_Lake_(region)",
    image:
      "https://res.cloudinary.com/dv5szgigi/image/upload/c_scale,q_auto,w_381/v1661899686/extended-night-companion/region-covers/mystery-lake.webp",

    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt6b1fpc05431s3318woru52`,
        cairn: `${maps.baseUrl}/ckt6b1fpc05441s33w99x0j9b`,
      },
      ru: {
        complete: `${maps.baseUrl}/clb7zxari1460780q446aju39k4`,
      },
    },
    locations: [
      "Camp Office",
      "Carter Hydro Dam",
      "Forestry Lookout",
      "Destroyed Lookout",
      "Trapper's Homestead",
      "Unnamed Pond",
      "Train Loading Area",
      "Rail Tunnel",
      "Frozen Creek",
      "Derailment",
      "Lake Trail",
      "Dave's Quiet Clearing",
      "Logging Camp",
      "Max's Last Stand",
      "Lake Overlook",
      "Clearcut",
      "Deadfall Area",
      "Alan's Cave",
      "Mystery Lake",
      "Ice Fishing Huts",
      "Mystery Lake (Western Access)",
      "Lake Cabins",
      "Lone Lake Cabin",
      "River (Northern Access)",
      "River (Southern Access)",
      "Train Bridge",
      "Lookout",
    ],
  },
  {
    difficulty: RegionDifficultyEnum.ADVANCED,
    hasAmmoWorkbench: false,
    hasForge: false,
    name: "pleasant-valley",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Pleasant_Valley",
    image:
      "https://res.cloudinary.com/dv5szgigi/image/upload/c_scale,q_auto,w_381/v1661899687/extended-night-companion/region-covers/pleasant-valley.webp",

    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt6b1fph05781s336ah7zhdf`,
        explorer: `${maps.baseUrl}/ckt6b1flt04911s33qc7z74ov`,
        ["spoiler-free"]: `${maps.baseUrl}/ckt6b1fpc05471s33q818f50i`,
        cairn: `${maps.baseUrl}/ckt6b1flr04891s33hq3edy22`,
        story: `${maps.baseUrl}/ckt6b1flq04851s33iduxebme`,
      },
      ru: {
        complete: `${maps.baseUrl}/clb803fin1465880q448exrdrmb`,
      },
    },
    locations: [
      "Barn (Molly's)",
      "Burned Ridge Cave",
      "Cascading Falls",
      "Cinder Hills Coal Mine",
      "Crash Site (Pleasant Valley)",
      "Derelict Cabins",
      "Draft Dodger's Cabin",
      "End of the Road",
      "Forest Cave",
      "Heartbreak Bridge",
      "Hilltop Cave",
      "Lonely Homestead",
      "Long Curve",
      "Lower Falls",
      "Misty Falls Picnic Area",
      "Pensive Pond",
      "Picnic Spot",
      "Pleasant Valley Farmstead",
      "Pleasant Valley Outbuildings",
      "Point of Disagreement",
      "Prepper's Abandoned Cache",
      "Signal Hill",
      "Skeeter's Ridge",
      "Thomson's Crossing",
      "Three Strikes Farmstead",
      "Upper Falls",
    ],
  },
  {
    difficulty: RegionDifficultyEnum.ADVANCED,
    hasAmmoWorkbench: false,
    hasForge: false,
    name: "timberwolf-mountain",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Timberwolf_Mountain",
    image:
      "https://res.cloudinary.com/dv5szgigi/image/upload/c_scale,q_auto,w_381/v1661899687/extended-night-companion/region-covers/timberwolf-mountain.webp",

    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt6b1ftg06941s33sglvz0vo`,
        explorer: `${maps.baseUrl}/ckt6b1fw807741s33utlkx27d`,
        ["spoiler-free"]: `${maps.baseUrl}/ckt6b1flr04871s33yok5j24v`,
        cairn: `${maps.baseUrl}/ckt6b1fth07011s33w4c6qs3w`,
      },
      ru: {
        complete: `${maps.baseUrl}/clb806znc1467810q44cocegssm`,
      },
    },
    locations: [
      "Mountaineer's Hut",
      "Wing",
      "Forest Cave",
      "Tail Section",
      "Landing Gear",
      "Echo Ravine",
      "Chasm Cave",
      "Secluded Shelf",
      "Cave 2",
      "Echo Peak West",
      "Engine",
      "Cave 1",
      "Crystal Lake",
      "Eric's Falls",
      "Waterfall Cave",
      "Deer Clearing",
      "Summit",
      "Echo Peak East",
    ],
  },
  {
    difficulty: RegionDifficultyEnum.INTERMEDIATE,
    hasAmmoWorkbench: false,
    hasForge: false,
    name: "transfer-pass",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Transfer_Pass",
    image:
      "https://db3rv8e6wd0zt.cloudfront.net/bddef61e-40cc-48fb-a62b-fc7e7be89ea3.webp",

    maps: {
      en: {
        explorer: `${maps.baseUrl}/cleppejsv9330540umczrgnaobt`,
        ["spoiler-free"]: `${maps.baseUrl}/cleppfbof9331090umc2mfcybi6`,
      },
      ru: {
        explorer: `${maps.baseUrl}/clepq5gbw9342380umcd8ldnieu`,
      },
    },
    locations: [
      "Broad Aerie",
      "Conductor's Dropoff",
      "Distempered Flatcars",
      "Feedwater Cave",
      "Immutable Hesitation",
      "Loitering Marsh",
      "Vacant Depot",
    ],
  },
  {
    hasAmmoWorkbench: false,
    hasForge: false,
    name: "world",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Great_Bear_Island",
    image:
      "https://res.cloudinary.com/dv5szgigi/image/upload/c_scale,q_auto,w_381/v1661899693/extended-night-companion/region-covers/world.webp",
    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt6b1fgz04491s33s4z7ql3o`,
      },
      ru: {
        complete: `${maps.baseUrl}/clb820hj81481250q44wv6y8726`,
      },
    },
    locations: [],
  },
];
