import {
  CloseIcon,
  FlatList,
  Pressable,
  SearchIcon,
  Spacer,
} from "native-base";
import React, { useCallback, useState } from "react";
import { ListRenderItem } from "react-native";
import { Input } from "../../components/Input";
import { Clothe, Maybe } from "../../generated/graphql";
import { useI18n } from "../../hooks/use18n";
import { useDebounce } from "../../hooks/useDebounce";

type Item = Pick<Clothe, "id" | "description">;

interface ItemListProps {
  data?: Maybe<Array<Item>>;
  loading: boolean;
  renderItem: ListRenderItem<Item>;
}

export const ItemList: React.FunctionComponent<ItemListProps> = (props) => {
  const { data = [], renderItem } = props;
  const { translate } = useI18n();
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 1000);

  const keyExtractor = useCallback((item: Item): string => item.id, []);
  const ItemSeparatorComponent = useCallback(() => <Spacer size="6" />, []);

  const filterItemByQuery = useCallback(
    (item: Item) => {
      if (debouncedQuery.length >= 2) {
        const translatedName = translate(item.description as any);
        const lowerCaseName = translatedName.toLowerCase();
        const lowerCaseQuery = debouncedQuery.toLowerCase();
        const result = lowerCaseName.includes(lowerCaseQuery);

        return result;
      } else {
        return true;
      }
    },
    [debouncedQuery]
  );

  return (
    <>
      <Input
        placeholder="Search"
        InputLeftElement={<SearchIcon ml="2" color="primary.300" />}
        InputRightElement={
          query ? (
            <Pressable onPress={() => setQuery("")}>
              <CloseIcon mr="2" color="primary.300" />
            </Pressable>
          ) : undefined
        }
        onChangeText={setQuery}
        value={query}
      />
      <Spacer h="4" />
      <FlatList
        data={data?.filter(filterItemByQuery)}
        ItemSeparatorComponent={ItemSeparatorComponent}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
      />
    </>
  );
};
