import Constants from "expo-constants";

type ReturnType = {
  manifest: typeof Constants["manifest"] | undefined;
};

export function useConstants(): ReturnType {
  if (Constants) {
    return { manifest: Constants.manifest };
  }

  return { manifest: undefined };
}
