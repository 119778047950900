// import AsyncStorage from "@react-native-async-storage/async-storage";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import { openURL } from "expo-linking";
import {
  Box,
  Button,
  Center,
  Column,
  Modal,
  Pressable,
  Radio,
  Text,
} from "native-base";
import React, { useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { AppBar } from "../../components/AppBar";
import { Line } from "../../components/Line";
import { NavigationRow } from "../../components/NavigationRow";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useI18n } from "../../hooks/use18n";
import { useConstants } from "../../hooks/useConstants";
import { sharedStyles, spacing } from "../../sharedStyles";
import { getLanguageDisplayName } from "../../utils/getLanguageDisplayName";
import { getUserLocale, setLocale } from "../../utils/userLocaleSlice";
import {
  getTemperatureDisplayMode,
  getWeightDisplayMode,
  toggleTemperatureDisplayMode,
  toggleWeightDisplayMode,
} from "../Items/unitDisplayModeSlice";
import { RouteNames } from "../Maps/constants";
import { handleFeedbackPress } from "./utils/handleFeedbackPress";
import { SUPPORTED_LOCALES } from "../../Constants";
import { IAvailableLanguages } from "../../types";
import { IRadioGroupOnChangeHandler } from "native-base/lib/typescript/components/primitives/Radio/types";

export const Settings: React.FunctionComponent = () => {
  const Constants = useConstants();
  const { translate } = useI18n();
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const dispatch = useAppDispatch();
  const locale = useAppSelector(getUserLocale);
  const weightDisplayMode = useAppSelector(getWeightDisplayMode);
  const temperatureDisplayMode = useAppSelector(getTemperatureDisplayMode);

  const [isShowingMeasurementPreferences, setIsShowingMeasurementPreferences] =
    useState(false);
  const [isShowingLanguagePreferences, setIsShowingLanguagePreferences] =
    useState(false);

  const handleAttributionsPress = (): void => {
    navigation.navigate(RouteNames.Attributions.name);
  };

  const handleChangeLogPress = (): void => {
    navigation.navigate(RouteNames.ChangeLog.name);
  };

  const handlePrivacyPress = (): void => {
    openURL("https://extendednightcompanion.com/privacy");
  };

  const handleLanguageChange: IRadioGroupOnChangeHandler = (value) => {
    dispatch(setLocale(value as IAvailableLanguages));
    setIsShowingLanguagePreferences(false);
  };

  return (
    <>
      <Modal
        isOpen={isShowingMeasurementPreferences}
        onClose={() => setIsShowingMeasurementPreferences(false)}
      >
        <Modal.CloseButton />

        <Modal.Content maxWidth="400px">
          <Modal.Header>Measurment Preferences</Modal.Header>
          <Column py="2" px="8" space="2">
            <Button
              textTransform="capitalize"
              onPress={() => dispatch(toggleTemperatureDisplayMode())}
            >
              <Text textTransform="capitalize">{temperatureDisplayMode}</Text>
            </Button>
            <Button
              textTransform="capitalize"
              onPress={() => dispatch(toggleWeightDisplayMode())}
            >
              <Text textTransform="capitalize">{weightDisplayMode}</Text>
            </Button>
          </Column>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                onPress={() => {
                  setIsShowingMeasurementPreferences(false);
                }}
              >
                <Text textTransform="capitalize">
                  {translate("common.close")}
                </Text>
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal
        isOpen={isShowingLanguagePreferences}
        onClose={() => setIsShowingLanguagePreferences(false)}
      >
        <Modal.Content>
          <Modal.Header>
            <Text textTransform="capitalize">
              {translate("modules.settings.language")}
            </Text>
          </Modal.Header>
          <Column p="8" space="2">
            <Radio.Group
              name="language"
              onChange={handleLanguageChange}
              value={locale}
              space="8"
            >
              {SUPPORTED_LOCALES.map((languageCode) => (
                <Radio
                  key={languageCode}
                  value={languageCode}
                  textTransform="capitalize"
                  w="full"
                >
                  {getLanguageDisplayName(languageCode)}
                </Radio>
              ))}
            </Radio.Group>
          </Column>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                onPress={() => {
                  setIsShowingLanguagePreferences(false);
                }}
              >
                <Text textTransform="capitalize">
                  {translate("common.close")}
                </Text>
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Box flex="1">
        <AppBar title={translate("modules.settings.settings")} />

        <ScrollView style={sharedStyles.screenContainer}>
          <Text fontSize="xl" textTransform="capitalize">
            {translate("modules.settings.preference_plural")}
          </Text>

          <Pressable
            style={styles.mapTypeStyles}
            onPress={() => setIsShowingLanguagePreferences(true)}
          >
            <>
              <Text fontSize="lg" textTransform="capitalize">
                {translate("modules.settings.language")}
              </Text>
              <Text pl="4" color="primary.200">
                {getLanguageDisplayName(locale)}
              </Text>
            </>
          </Pressable>
          <Pressable
            style={styles.mapTypeStyles}
            onPress={() => setIsShowingMeasurementPreferences(true)}
          >
            <>
              <Text fontSize="lg" textTransform="capitalize">
                {translate("modules.settings.measurement_plural")}
              </Text>
              <Text pl="4" textTransform="capitalize" color="primary.200">
                {temperatureDisplayMode}, {weightDisplayMode}
              </Text>
            </>
          </Pressable>
          <Line />
          <Text fontSize="xl" textTransform="capitalize">
            {translate("modules.settings.about")}
          </Text>

          <Pressable onPress={handleChangeLogPress}>
            <NavigationRow>
              <Text textTransform="capitalize">
                {translate("modules.settings.change-log")}
              </Text>
            </NavigationRow>
          </Pressable>
          <Pressable onPress={handleAttributionsPress}>
            <NavigationRow>
              <Text textTransform="capitalize">
                {translate("modules.settings.attributions")}
              </Text>
            </NavigationRow>
          </Pressable>
          <Pressable onPress={handleFeedbackPress}>
            <NavigationRow>
              <Text textTransform="capitalize">
                {translate("modules.settings.feedback")}
              </Text>
            </NavigationRow>
          </Pressable>
          <Pressable onPress={handlePrivacyPress}>
            <NavigationRow>
              <Text textTransform="capitalize">
                {translate("modules.settings.privacy-policy")}
              </Text>
            </NavigationRow>
          </Pressable>
        </ScrollView>
        <Center mb="2">
          <Text fontSize="xs">
            v{Constants?.manifest?.version} (
            {Constants?.manifest?.releaseChannel})
          </Text>
        </Center>
      </Box>
    </>
  );
};

const styles = StyleSheet.create({
  toggleRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
  mapTypeStyles: {
    paddingVertical: spacing.sm,
  },
  switch: {
    flex: 2,
  },
});
