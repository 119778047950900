import { Column, Row, Text } from "native-base";
import React, { useCallback } from "react";
import { AppBar } from "../../components/AppBar";
import { Food as FoodType } from "../../generated/graphql";
import { useI18n } from "../../hooks/use18n";
import { ItemCard } from "./components/ItemCard";
import { ScreenContainer } from "./components/ScreenContainer";
import { useFood } from "./hooks/useFood";
import { ItemList } from "./ItemList";

export const Food: React.FunctionComponent = () => {
  const { translate } = useI18n();
  const { data, loading } = useFood();

  const renderItem = useCallback(
    ({ item }: { item: FoodType }) => (
      <ItemCard key={item.id} item={item}>
        <Column>
          <Row mx="4" alignItems="baseline">
            <Text fontSize="md">
              {item.hydration}
              <Text ml="0.5" color="primary.100" fontSize="xs">
                %
              </Text>
            </Text>
            <Row>
              <Text ml="2">Hydration</Text>
            </Row>
          </Row>
          <Row mx="4">
            <Text fontSize="md">
              {item.calories}{" "}
              <Text color="primary.100" fontSize="xs">
                kcal
              </Text>
            </Text>
          </Row>
        </Column>
      </ItemCard>
    ),
    []
  );

  return (
    <ScreenContainer>
      <AppBar goBack title={translate("modules.item.food")} />
      <ItemList data={data} loading={loading} renderItem={renderItem} />
    </ScreenContainer>
  );
};
