import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { update } from "ramda";
import { IEntry, IJournal, IJournalState } from "./types";

const initialState: IJournalState = {
  default: {
    id: "default",
    name: "Default",
    desc: "",
    entries: [],
  },
};

type IAction = PayloadAction<{
  journalId: string;
  entry: IEntry;
}>;

type IPutAction = PayloadAction<{
  journalId: string;
  entry: Partial<IEntry> & { id: IEntry["id"] };
}>;
type IDeleteJournalEntriesAction = PayloadAction<{ journalId: string }>;
type IJournalAction = PayloadAction<IJournal>;
type IPartialJournalAction = PayloadAction<
  Partial<IJournal> & { id: IJournal["id"] }
>;

export const journalSlice = createSlice({
  name: "journalEntries",
  initialState: initialState,
  reducers: {
    addJournal: (state, action: IJournalAction) => {
      state[action.payload.id] = action.payload;
    },
    deleteJournal: (state, action: IJournalAction) => {
      delete state[action.payload.id];
    },
    putJournal: (state, action: IPartialJournalAction) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload,
      };
    },
    addJournalEntry: (state, action: IAction) => {
      state[action.payload.journalId].entries.push(action.payload.entry);
    },
    deleteJournalEntry: (state, action: IAction) => {
      const newJournalEntries = state[action.payload.journalId].entries.filter(
        (entry) => entry.id !== action.payload.entry.id
      );
      state[action.payload.journalId].entries = newJournalEntries;
    },
    deleteAllJournalEntries: (state, action: IDeleteJournalEntriesAction) => {
      state[action.payload.journalId].entries = [];
    },
    putJournalEntry: (state, action: IPutAction) => {
      const journalId = action.payload.journalId;
      const newEntryPartial = action.payload.entry;
      const targetEntryIndex = state[journalId].entries.findIndex(
        (j) => j.id === newEntryPartial.id
      );
      const targetJournalEnries = state[journalId].entries[targetEntryIndex];

      const updatedEntry = { ...targetJournalEnries, ...newEntryPartial };
      const updatedJournalEntries = update(
        targetEntryIndex,
        updatedEntry,
        state[journalId].entries
      );

      return {
        ...state,
        [journalId]: { ...state[journalId], entries: updatedJournalEntries },
      };
    },
  },
});

export const {
  //Journals
  addJournal,
  putJournal,
  deleteJournal,
  //Entries
  addJournalEntry,
  deleteJournalEntry,
  deleteAllJournalEntries,
  putJournalEntry,
} = journalSlice.actions;

export default journalSlice.reducer;
