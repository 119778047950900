import { sortBy, prop } from "ramda";
import { useEffect, useState } from "react";
import { IClothing, IItem } from "../../../types";

export function useItems() {
  const [isLoading, setIsLoading] = useState(true);
  const [food, setFood] = useState<IItem[]>([]);
  const [tools, setTools] = useState<IItem[]>([]);
  const [clothing, setClothing] = useState<IClothing[]>([]);
  const [materials, setMaterials] = useState<IItem[]>([]);
  const [firstAid, setFirstAid] = useState<IItem[]>([]);

  const loadItemData = async () => {
    const { foodData } = await import("./../data/foodData");

    const {
      cookingToolsData,
      fireStartingToolsData,
      fishingToolsData,
      handToolsData,
      huntingToolsData,
      lightingToolsData,
      otherToolsData,
      repairToolsData,
      sleepToolsData,
    } = await import("../data/toolsData");

    const { materialsData } = await import("./../data/materialsData");

    const {
      accessoryClothes,
      chestClothes,
      feetClothes,
      handClothes,
      headClothes,
      legClothes,
      outerClothes,
      outerFeetClothes,
      outerLegClothes,
    } = await import("../data/clothingData");
    const { preventativeFirstAidData, treatmentFirstAidData } = await import(
      "./../data/firstAidData"
    );

    const clothing = [
      ...accessoryClothes(),
      ...chestClothes(),
      ...feetClothes(),
      ...handClothes(),
      ...headClothes(),
      ...legClothes(),
      ...outerClothes(),
      ...outerFeetClothes(),
      ...outerLegClothes(),
    ];
    const firstAid = [
      ...preventativeFirstAidData(),
      ...treatmentFirstAidData(),
    ];
    const food = foodData;
    const materials = materialsData;
    const tools = [
      ...cookingToolsData(),
      ...lightingToolsData(),
      ...fireStartingToolsData(),
      ...fishingToolsData(),
      ...handToolsData(),
      ...huntingToolsData(),
      ...repairToolsData(),
      ...sleepToolsData(),
      ...otherToolsData(),
    ];

    return { clothing, firstAid, materials, tools, food };
  };

  /**
   * Loading in all the data makes some pages chug
   * Importing here async to allow pages to transition
   * smoothly before loading in all the data
   */
  useEffect(() => {
    const timeout = setTimeout(async () => {
      setIsLoading(true);
      const { clothing, firstAid, materials, tools, food } =
        await loadItemData();

      setClothing(sortBy(prop("desc"), clothing));
      setFirstAid(sortBy(prop("desc"), firstAid));
      setMaterials(sortBy(prop("desc"), materials()));
      setTools(sortBy(prop("desc"), tools));
      setFood(sortBy(prop("desc"), food()));

      setIsLoading(false);
    }, 800);

    return () => clearTimeout(timeout);
  }, []);

  return {
    isLoading: isLoading,
    firstAid,
    food,
    materials,
    tools,
    clothing,
  };
}
