import { StyleSheet } from "react-native";

export const spacing = {
  xs: 5,
  sm: 10,
  md: 20,
  lg: 40,
};

export const sharedStyles = StyleSheet.create({
  background: { backgroundColor: "#0b233e" },
  row: { flexDirection: "row" },
  column: { flexDirection: "column" },
  justifyCenter: { justifyContent: "center" },
  itemsCenter: { alignItems: "center" },
  flex1: { flex: 1 },
  textCenter: { textAlign: "center" },
  center: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  debug: {
    backgroundColor: "red",
  },
  screenContainer: {
    flex: 1,
  },
});

export const appHorizontalMargin = 15;
export const appNBMarginHorizontalProp = [
  `${appHorizontalMargin}px`,
  `${appHorizontalMargin}px`,
  `${appHorizontalMargin}px`,
  0,
  0,
];
