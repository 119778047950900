import { RouteProp, useRoute } from "@react-navigation/native";
import React from "react";
import { View } from "react-native";
import { RootStackParamList } from "../../types";
import { sharedStyles } from "./../../sharedStyles";
import { caveData } from "./data/caveData";
import { regions } from "./data/regionsData";
import { transitionsData } from "./data/transitionsData";
import { ImageMap } from "./ImageMap";
import { IMapSetting, IRegion, IRegionNames } from "./types";
import { useKeepAwake } from "expo-keep-awake";

export interface MapScreenParams {
  locale: string;
  /**
   * Can be in key from regions, cave, or transition data
   */
  name: IRegionNames | string;
  type: IMapSetting;
}

export const MapScreen: React.FunctionComponent = () => {
  useKeepAwake();
  const data: any[] = [...caveData, ...regions, ...transitionsData];
  const route = useRoute<RouteProp<RootStackParamList, "map">>();
  const targetName = route.params?.name;
  const type = route.params?.type;
  const locale = route.params?.locale as "ru" | "en";
  const area = Object.values(data).find((info) => {
    return info.name === targetName;
  }) as IRegion;

  return (
    <View style={sharedStyles.flex1}>
      <View style={[{ flex: 1, marginHorizontal: 0 }]}>
        {area ? <ImageMap region={area} type={type} locale={locale} /> : null}
      </View>
    </View>
  );
};
