import {
  createDrawerNavigator,
  DrawerNavigationOptions,
} from "@react-navigation/drawer";
import { Box, useBreakpointValue, useTheme } from "native-base";
import React, { useCallback } from "react";
import { colors } from "./colors";
import { DrawerContent } from "./components/DrawerContent";
import { Dashboard } from "./modules/Dashboard/Dashboard";
import { Clothing } from "./modules/Items/Clothing";
import { FirstAid } from "./modules/Items/FirstAid";
import { Food } from "./modules/Items/Food";
import { ItemCategories } from "./modules/Items/ItemCategories";
import { Materials } from "./modules/Items/Materials";
import { Tools } from "./modules/Items/Tools";
import { JournalNavigator } from "./modules/Journal/JournalNavigator";
import { RouteNames } from "./modules/Maps/constants";
import { Maps } from "./modules/Maps/Maps";
import { MapScreen } from "./modules/Maps/MapScreen";
import { RegionDetailScreen } from "./modules/Maps/RegionDetailScreen";
import { Attributions } from "./modules/Settings/Attributions";
import { ChangeLog } from "./modules/Settings/ChangeLog";
import { Settings } from "./modules/Settings/Settings";
import { TrophyNavigator } from "./modules/Trophies/TrophyNavigator";
import { RootStackParamList } from "./types";
import { useAppSetup } from "./hooks/useAppSetup";

const Drawer = createDrawerNavigator<RootStackParamList>();

export const MainNavigation: React.FunctionComponent = () => {
  useAppSetup();
  const theme = useTheme();
  const drawerType = useBreakpointValue({
    base: "slide",
    lg: "permanent",
  });
  const sceneWidth = useBreakpointValue({
    base: theme.space["full"],
    lg: theme.space["3/5"],
    xl: theme.space["3/5"],
  });

  const drawerScreenOptions: DrawerNavigationOptions = {
    drawerType: drawerType,
    headerShown: false,
    drawerStyle: {
      borderRightColor: "transparent",
      backgroundColor: colors.primary[900],
    },
    sceneContainerStyle: {
      flex: 1,
    },
  };

  const drawerContent = useCallback(
    (props: any) => <DrawerContent {...props} />,
    []
  );

  const screenOptions: DrawerNavigationOptions = {
    headerShown: false,
    drawerContentContainerStyle: {
      width: Number(theme.space["1/5"]),
    },
    sceneContainerStyle: {
      backgroundColor: theme.colors["primary"]["900"],
      maxWidth: sceneWidth,

      paddingHorizontal: theme.space["4"],
    },
  };
  return (
    <Box flex="1" background={"primary.900"}>
      <Drawer.Navigator
        backBehavior="history"
        screenOptions={drawerScreenOptions}
        drawerContent={drawerContent}
      >
        <Drawer.Screen
          name={RouteNames.Dashboard.name}
          component={Dashboard}
          options={screenOptions}
        />
        <Drawer.Screen name={RouteNames.Map.name} component={MapScreen} />
        <Drawer.Screen
          name={RouteNames.Region.name}
          component={RegionDetailScreen}
          options={screenOptions}
        />
        <Drawer.Screen
          name={RouteNames.Maps.name}
          component={Maps}
          options={screenOptions}
        />
        <Drawer.Screen
          name={RouteNames.Settings.name}
          component={Settings}
          options={screenOptions}
        />
        <Drawer.Screen
          name={RouteNames.Attributions.name}
          component={Attributions}
          options={screenOptions}
        />
        <Drawer.Screen
          name="clothing"
          component={Clothing}
          options={screenOptions}
        />
        <Drawer.Screen
          name="items"
          component={ItemCategories}
          options={screenOptions}
        />
        <Drawer.Screen name="food" component={Food} options={screenOptions} />
        <Drawer.Screen
          name="materials"
          component={Materials}
          options={screenOptions}
        />
        <Drawer.Screen
          name="firstAid"
          component={FirstAid}
          options={screenOptions}
        />
        <Drawer.Screen name="tools" component={Tools} options={screenOptions} />
        <Drawer.Screen
          name={RouteNames.ChangeLog.name}
          component={ChangeLog}
          options={screenOptions}
        />

        <Drawer.Screen
          name={RouteNames.Trophies.name}
          component={TrophyNavigator}
          options={screenOptions}
        />

        <Drawer.Screen
          name={RouteNames.Journal.name}
          component={JournalNavigator}
          options={screenOptions}
        />
      </Drawer.Navigator>
    </Box>
  );
};
