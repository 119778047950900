import { useGetFoodQuery } from "../../../generated/graphql";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { getUserLocale } from "../../../utils/userLocaleSlice";

export function useFood() {
  const locale = useAppSelector(getUserLocale);
  const results = useGetFoodQuery({ variables: { locale } });
  const foodData = results?.data?.foods ?? [];
  const formattedData = foodData.map((foodItem) => {
    return {
      ...foodItem,
      name: foodItem.name_translations
        ? foodItem.name_translations[0].text
        : "",
    };
  });

  return { ...results, data: formattedData };
}
