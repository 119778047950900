import { Box } from "native-base";
import React from "react";
import { AppBar } from "../../components/AppBar";
import { appNBMarginHorizontalProp } from "../../sharedStyles";
import { kebabToTitleCase } from "../../utils/calculations";
import { IMapSetting, IRegion, ITransition } from "./types";

interface ImageMapProps {
  locale: "ru" | "en";
  region: ITransition | IRegion;
  type: IMapSetting;
}

export const ImageMap: React.FunctionComponent<ImageMapProps> = (props) => {
  const style = { flex: 1, borderWidth: 0 };
  const { region, locale, type } = props;

  return (
    <Box flex="1">
      <Box mx={appNBMarginHorizontalProp}>
        <AppBar title={kebabToTitleCase(props.region?.name)} />
      </Box>
      <Box h="full" mx={-20}>
        <iframe
          style={style}
          src={region.maps[locale]?.[type] || region.maps["en"]?.[type]}
        />
      </Box>
    </Box>
  );
};
