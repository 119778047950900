import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import {
  ArrowBackIcon,
  Box,
  HamburgerIcon,
  Hidden,
  IconButton,
  Pressable,
  Row,
  StatusBar,
  Text,
} from "native-base";
import React from "react";

interface AppBarProps {
  actions?: Array<{
    onPress?: () => void;
    icon: React.ElementType;
  }>;
  goBack?: boolean;
  title?: string;
}

export const AppBar: React.FunctionComponent<AppBarProps> = (props) => {
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const { actions = [], goBack = false, title = "" } = props;

  return (
    <>
      <StatusBar barStyle="light-content" />
      <Box safeAreaTop />
      <Row
        mt="3"
        justifyContent="space-between"
        alignItems="center"
        w="full"
        minH="44"
      >
        <Row h="full" justifyContent="flex-start" alignItems="center">
          {navigation.canGoBack() && goBack ? (
            <IconButton
              icon={<ArrowBackIcon color="white" size="lg" />}
              onPress={navigation.goBack}
              p={0}
              mr={4}
              hitSlop={20}
            />
          ) : (
            <Hidden from="lg">
              <IconButton
                icon={<HamburgerIcon size="xl" name="menu" color="white" />}
                onPress={navigation.openDrawer}
                p={0}
                mr={4}
                hitSlop={10}
              />
            </Hidden>
          )}
          <Text
            color="white"
            fontSize="lg"
            fontWeight="bold"
            textTransform={"capitalize"}
          >
            {title}
          </Text>
        </Row>
        <Row space="2" alignItems="center">
          {actions.map((action, index) =>
            action.onPress ? (
              <Pressable
                w="10"
                h="10"
                key={index}
                onPress={action.onPress}
                alignItems="center"
                justifyContent="center"
              >
                <action.icon />
              </Pressable>
            ) : (
              <Box
                w="10"
                h="10"
                key={index}
                alignItems="center"
                justifyContent="center"
              >
                <action.icon />
              </Box>
            )
          )}
        </Row>
      </Row>
    </>
  );
};
