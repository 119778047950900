import { maps } from "../../../Constants";
import { ITransition } from "../types";

export const caveData: ITransition[] = [
  {
    name: "fm-cave",
    fandomUrl: "https://thelongdark.fandom.com/wiki/Cave_in_Forlorn_Muskeg",
    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt8p7nkb0873btaq77cee909`,
      },
    },
    regionKeys: ["forlorn-muskeg"],
  },
  {
    name: "twm-cave",
    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt8p7n740826btaq9xa2hgxv`,
      },
    },
    regionKeys: ["timberwolf-mountain"],
  },
  {
    name: "gold-mine",
    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt8p7mwt0807btaqvc4pxhwc`,
      },
    },
    regionKeys: ["ash-canyon"],
  },
  {
    name: "ac-cave",
    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt8p7n5w0817btaqhbcxbp5k`,
      },
    },
    regionKeys: ["ash-canyon"],
  },
  {
    name: "ice-cave",
    maps: {
      en: {
        "spoiler-free": `${maps.baseUrl}/ckt8p7n5w0818btaqnhsr7xab`,
        complete: `${maps.baseUrl}/ckt8p7n9a0835btaqxmgsb43d`,
      },
    },
    regionKeys: ["hushed-river"],
  },
  {
    name: "dp-cave",
    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt8p7m5i0772btaqoa8m3hg6`,
      },
    },
    regionKeys: ["desolation-point"],
  },
  {
    name: "abondoned-mine-no-5",
    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt8p7nd10851btaq04t36h0q`,
      },
    },
    regionKeys: ["desolation-point"],
  },
  {
    name: "abondoned-mine-lower",
    maps: {
      en: {
        complete: `${maps.baseUrl}/ckt8p7me30788btaqgjj1ld4x`,
      },
    },
    regionKeys: ["coastal-highway"],
  },
];
