import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import {
  AddIcon,
  Box,
  Button,
  Center,
  Column,
  Radio,
  Row,
  Text,
} from "native-base";
import React, { useCallback } from "react";
import { FlatList, LayoutAnimation, Platform, UIManager } from "react-native";
import { AppBar } from "../../components/AppBar";
import { Line } from "../../components/Line";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useI18n } from "../../hooks/use18n";
import { sharedStyles } from "../../sharedStyles";
import { putJournalId } from "./currentJournalIdSlice";
import { deleteJournal } from "./journalSlice";
import { IJournal } from "./types";
import { alert } from "../../utils/Alert";

if (Platform.OS === "android") {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

export const JournalList: React.FunctionComponent = () => {
  const { translate } = useI18n();
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const currentJournalId = useAppSelector((state) => state.currentJournalId);
  const journals = useAppSelector(
    (state) => Object.values(state.journals) || []
  );
  const dispatch = useAppDispatch();
  const renderEmpty = useCallback(
    () => (
      <Center>
        <Text>No Journals</Text>
      </Center>
    ),
    []
  );

  const handleJournalPress = useCallback((newJournalId: IJournal["id"]) => {
    dispatch(putJournalId(newJournalId));
  }, []);

  const handleEditPress = useCallback((journal: IJournal) => {
    navigation.navigate("AddJournal", { journal });
  }, []);

  const handleDeletePress = useCallback(
    (journal: IJournal) => {
      alert(
        "Delete Journal",
        `Are you sure you want to delete the "${journal?.name}" journal?`,
        [
          {
            text: translate("common.cancel"),
            style: "cancel",
          },
          {
            text: translate("modules.journal.delete"),
            style: "destructive",
            onPress: () => {
              // Change the current journal id if the one they delete
              // is the current journal
              if (journal.id === currentJournalId) {
                const newJournal = journals.find(
                  (journal) => journal.id !== currentJournalId
                );
                if (newJournal) {
                  dispatch(putJournalId(newJournal.id));
                }
              }

              LayoutAnimation.configureNext(
                LayoutAnimation.Presets.easeInEaseOut
              );

              dispatch(deleteJournal(journal));
            },
          },
        ]
      );
    },
    [journals]
  );

  const renderItem = useCallback(
    ({ item }: { item: IJournal }) => (
      <Row alignItems="center">
        <Radio.Group
          name="journal-list"
          onChange={() => handleJournalPress(item.id)}
          flex="1"
          value={currentJournalId}
        >
          <Radio value={item.id}>
            <Column ml="2">
              <Column>
                <Text fontSize="xl">{item.name}</Text>
                {item.desc ? <Text fontSize="xs">{item.desc}</Text> : null}
              </Column>
              <Row>
                <Button variant="ghost" onPress={() => handleEditPress(item)}>
                  <Text textTransform="capitalize" color="primary.100">
                    {translate("modules.journal.edit")}
                  </Text>
                </Button>
                <Button
                  variant="ghost"
                  disabled={journals.length <= 1}
                  onPress={() => handleDeletePress(item)}
                >
                  <Text
                    textTransform="capitalize"
                    color={journals.length <= 1 ? "primary.500" : "primary.100"}
                  >
                    {translate("modules.journal.delete")}
                  </Text>
                </Button>
              </Row>
            </Column>
          </Radio>
        </Radio.Group>
      </Row>
    ),
    [journals]
  );

  const handleAddPress = useCallback(() => {
    navigation.navigate("AddJournal");
  }, [journals]);

  return (
    <Box flex="1">
      <AppBar
        actions={[{ icon: AddIcon, onPress: handleAddPress }]}
        title={` ${translate("modules.journal.journal-list")}`}
        goBack
      />
      <FlatList
        key="id"
        data={journals}
        renderItem={renderItem}
        ItemSeparatorComponent={Line}
        contentContainerStyle={journals.length === 0 && sharedStyles.flex1}
        style={sharedStyles.screenContainer}
        ListEmptyComponent={renderEmpty}
      />
    </Box>
  );
};
