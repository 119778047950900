import { ChevronLeftIcon, ChevronRightIcon, Pressable, Row } from "native-base";
import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, TextInput } from "react-native";
import { usePrevious } from "../../../hooks/usePrevious";
import { spacing } from "../../../sharedStyles";

interface IProps {
  initialNumber?: number;
  onChange?: (num: number) => void;
}

export const PositiveNumberStepper: React.FunctionComponent<IProps> = (
  props
) => {
  const [num, setNum] = useState(props.initialNumber || 0);
  const prevNum = usePrevious(num);

  useEffect(() => {
    if (props.onChange) {
      if (prevNum !== num) {
        props.onChange(num);
      }
    }
  }, [prevNum, num]);

  const handleDayChange = useCallback((newDay: string) => {
    const newDayNum = Number(newDay);
    setNum(newDayNum);
  }, []);

  const incrementDay = useCallback(() => {
    setNum((prevDayNum) => {
      return prevDayNum + 1;
    });
  }, []);
  const decrementDay = useCallback(() => {
    setNum((prevDayNum) => {
      return prevDayNum >= 0 ? prevDayNum - 1 : prevDayNum;
    });
  }, []);

  return (
    <Row alignItems="center">
      <Pressable mr="4" onPress={decrementDay} disabled={num === 0}>
        <ChevronLeftIcon color={num === 0 ? "primary.600" : "white"} size={5} />
      </Pressable>
      <TextInput
        keyboardType={"number-pad"}
        value={String(num)}
        style={styles.input}
        onChangeText={handleDayChange}
      />
      <Pressable ml="4" onPress={incrementDay}>
        <ChevronRightIcon color={"white"} size={5} />
      </Pressable>
    </Row>
  );
};
const styles = StyleSheet.create({
  input: {
    textAlign: "center",
    width: 40,
    borderWidth: 1,
    borderStyle: "solid",
    paddingVertical: spacing.sm,
    color: "white",
    borderColor: "white",
    borderRadius: 10,
  },
});
