import { RouteProp } from "@react-navigation/native";
import { Box, Input, Radio, Spacer } from "native-base";
import React, { useCallback, useEffect, useState } from "react";
import { ScrollView } from "react-native";
import { AppBar } from "../../components/AppBar";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useI18n } from "../../hooks/use18n";
import { usePrevious } from "../../hooks/usePrevious";
import { kebabToTitleCase } from "../../utils/calculations";
import { regions } from "./../../modules/Maps/data/regionsData";
import { IRegion } from "./../../modules/Maps/types";
import { useJournalEntry } from "./hooks/useJournalEntry";
import { putJournalEntry } from "./journalSlice";
import { IParamList } from "./types";

type EditScreenRouteProp = RouteProp<IParamList, "EditLocation">;
interface IProps {
  route: EditScreenRouteProp;
}

export const EditLocation: React.FunctionComponent<IProps> = (props) => {
  const { translate } = useI18n();
  const journalId = useAppSelector((state) => state.currentJournalId);
  const dispatch = useAppDispatch();
  const { targetJournalEntry } = useJournalEntry(props.route.params.entryId);
  const selectedRegion = targetJournalEntry?.mapId;
  const [filterTerm, setFilterTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(
    targetJournalEntry?.location
  );
  const prevSelectedLocation = usePrevious(selectedLocation);
  const handleNewFilterTerm = useCallback((newFilterTerm: string) => {
    setFilterTerm(newFilterTerm);
  }, []);

  useEffect(() => {
    if (targetJournalEntry?.id) {
      if (prevSelectedLocation !== selectedLocation) {
        dispatch(
          putJournalEntry({
            journalId,
            entry: { id: targetJournalEntry.id, location: selectedLocation },
          })
        );
      }
    }
  }, [selectedLocation, prevSelectedLocation, targetJournalEntry]);

  const data: IRegion["locations"] | undefined = regions.find(
    (region) => region.name === selectedRegion
  )?.locations;

  return (
    <Box>
      <AppBar goBack title={translate("modules.map.location")} />
      <Input
        value={filterTerm}
        onChangeText={handleNewFilterTerm}
        placeholder={"Search"}
      />
      <Spacer size="2" />

      <ScrollView>
        <Radio.Group
          name="location-list"
          onChange={(newValue) => setSelectedLocation(newValue)}
          value={selectedLocation || ""}
          space="4"
        >
          {data
            ?.filter((location: string) => {
              return location.toLowerCase().includes(filterTerm.toLowerCase());
            })
            .sort()
            .map((location) => {
              return (
                <Radio key={location} value={location}>
                  {kebabToTitleCase(location)}
                </Radio>
              );
            })}
        </Radio.Group>
      </ScrollView>
    </Box>
  );
};
