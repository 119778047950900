import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  AsyncStorageWrapper,
  CachePersistor,
  persistCache,
} from "apollo3-cache-persist";
import { useEffect, useState } from "react";

const cache = new InMemoryCache();
const persistor = new CachePersistor({
  cache,
  storage: new AsyncStorageWrapper(AsyncStorage),
});

export function useApolloClient() {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    async function init() {
      await persistor.restore();

      // await before instantiating ApolloClient, else queries might run before the cache is persisted
      await persistCache({
        cache,
        storage: new AsyncStorageWrapper(AsyncStorage),
      });

      const newClient = new ApolloClient({
        uri: "https://silent-cartographer-production.up.railway.app/api/graphql",
        // uri: "http://localhost:9999/api/graphql",
        cache,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "cache-and-network",
          },
        },
      });

      newClient.onClearStore(async () => {
        await persistor.purge();
      });
      setClient(newClient);
    }

    init();
  }, []);

  return { client };
}
