import React from "react";
import { Path } from "react-native-svg";
import { Icon } from "native-base";

/**
 * Icon Site: https://icon-sets.iconify.design/mdi/
 * SVGR Site: https://react-svgr.com/playground/?native=true
 */

export const BagIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M16 5V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v1a4 4 0 0 0-4 4v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9a4 4 0 0 0-4-4m-6-1h4v1h-4V4m2 5 2 2-2 2-2-2 2-2m6 7H9v2H8v-2H6v-1h12v1Z"
    />
  </Icon>
);

export const BookIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M6 22q-.825 0-1.412-.587Q4 20.825 4 20V4q0-.825.588-1.413Q5.175 2 6 2h12q.825 0 1.413.587Q20 3.175 20 4v16q0 .825-.587 1.413Q18.825 22 18 22Zm5-11 2.5-1.5L16 11V4h-5Z"
    />
  </Icon>
);

export const DashboardIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
    />
  </Icon>
);

export const FirstAidIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M10 3 8 5v2H5C3.85 7 3.12 8 3 9L2 19c-.12 1 .54 2 2 2h16c1.46 0 2.12-1 2-2L21 9c-.12-1-.94-2-2-2h-3V5l-2-2h-4m0 2h4v2h-4V5m1 5h2v3h3v2h-3v3h-2v-3H8v-2h3v-3Z"
    />
  </Icon>
);

export const FoodIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M3 3a1 1 0 0 0-1 1v5.5c0 1.69 1.03 3.13 2.5 3.72v6.28A1.5 1.5 0 0 0 6 21a1.5 1.5 0 0 0 1.5-1.5v-6.28c1.47-.59 2.5-2.03 2.5-3.72V4a1 1 0 0 0-1-1 1 1 0 0 0-1 1v4a.5.5 0 0 1-.5.5A.5.5 0 0 1 7 8V4a1 1 0 0 0-1-1 1 1 0 0 0-1 1v4a.5.5 0 0 1-.5.5A.5.5 0 0 1 4 8V4a1 1 0 0 0-1-1m16.88 0c-.13 0-.26.09-.38.16L16 5.25V9h-4v2h1l1 10h6l1-10h1V9h-4V6.34l2.5-1.5c.5-.28.63-.84.34-1.34-.21-.36-.58-.55-.96-.5Z"
    />
  </Icon>
);

export const MapIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="m15 19-6-2.11V5l6 2.11M20.5 3h-.16L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5a.5.5 0 0 0 .5.5c.05 0 .11 0 .16-.03L9 18.9l6 2.1 5.64-1.9c.21-.1.36-.25.36-.48V3.5a.5.5 0 0 0-.5-.5Z"
    />
  </Icon>
);

export const LeafIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 256 256" {...props}>
    <Path
      fill="currentColor"
      d="M162.4 203a87 87 0 0 1-45.8 12.8h-3.3c-15.9-.6-32.1-5.2-48-13.8l100.4-100.3a8.1 8.1 0 0 0-11.4-11.4L54 190.7c-8.6-15.9-13.2-32.1-13.8-48A87.3 87.3 0 0 1 53 93.6C79.1 50.5 140.2 27.5 216.5 32a8.1 8.1 0 0 1 7.5 7.5c4.5 76.3-18.5 137.4-61.6 163.5Zm-105.3-6.9c-1.1-1.8-2.1-3.6-3.1-5.4l-19.7 19.6a8.1 8.1 0 0 0 0 11.4 8.2 8.2 0 0 0 11.4 0L65.3 202l-5.4-3.1a9.3 9.3 0 0 1-2.8-2.8Z"
    />
  </Icon>
);

export const PencilBoxIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-2.3 6.35c.22-.21.22-.56 0-.77L15.42 7.3a.532.532 0 0 0-.77 0l-1 1 2.05 2.05 1-1M7 14.94V17h2.06l6.06-6.06-2.06-2.06L7 14.94Z"
    />
  </Icon>
);

export const RainyIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M9 12c.53.14.85.69.71 1.22l-1.3 4.83c-.14.54-.69.85-1.22.71a.967.967 0 0 1-.69-1.22l1.28-4.83c.14-.54.69-.85 1.22-.71m4 0c.53.14.85.69.71 1.22l-2.07 7.73c-.14.55-.69.85-1.23.71-.53-.16-.85-.69-.71-1.23l2.08-7.72c.14-.54.69-.85 1.22-.71m4 0c.53.14.85.69.71 1.22l-1.3 4.83c-.14.54-.69.85-1.22.71a.967.967 0 0 1-.69-1.22l1.28-4.83c.14-.54.69-.85 1.22-.71m0-2V9a5 5 0 0 0-5-5C9.5 4 7.45 5.82 7.06 8.19 6.73 8.07 6.37 8 6 8a3 3 0 0 0-3 3c0 1.11.6 2.08 1.5 2.6v-.01c.5.28.64.91.37 1.37-.28.47-.87.64-1.37.36v.01A4.98 4.98 0 0 1 1 11a5 5 0 0 1 5-5c1-2.35 3.3-4 6-4 3.43 0 6.24 2.66 6.5 6.03L19 8a4 4 0 0 1 4 4c0 1.5-.8 2.77-2 3.46-.5.27-1.09.11-1.37-.37-.27-.48-.13-1.09.37-1.37v.01c.6-.34 1-.99 1-1.73a2 2 0 0 0-2-2h-2Z"
    />
  </Icon>
);

export const RunIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M16.5 5.5a2 2 0 0 0 2-2 2 2 0 0 0-2-2 2 2 0 0 0-2 2 2 2 0 0 0 2 2m-3.6 13.9 1-4.4 2.1 2v6h2v-7.5l-2.1-2 .6-3A7.298 7.298 0 0 0 22 13v-2c-1.76.03-3.4-.89-4.3-2.4l-1-1.6c-.36-.6-1-1-1.7-1-.3 0-.5.1-.8.1L9 8.3V13h2V9.6l1.8-.7-1.6 8.1-4.9-1-.4 2 7 1.4M4 9a1 1 0 0 1-1-1 1 1 0 0 1 1-1h3v2H4m1-4a1 1 0 0 1-1-1 1 1 0 0 1 1-1h5v2H5m-2 8a1 1 0 0 1-1-1 1 1 0 0 1 1-1h4v2H3Z"
    />
  </Icon>
);

export const ShieldIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M21 11c0 5.55-3.84 10.74-9 12-5.16-1.26-9-6.45-9-12V5l9-4 9 4v6m-9 10c3.75-1 7-5.46 7-9.78V6.3l-7-3.12L5 6.3v4.92C5 15.54 8.25 20 12 21Z"
    />
  </Icon>
);

export const ShirtIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M16 21H8a1 1 0 0 1-1-1v-7.93l-1.3 1a.996.996 0 0 1-1.41 0l-2.83-2.78a.996.996 0 0 1 0-1.41L7.34 3H9c0 1.1 1.34 2 3 2s3-.9 3-2h1.66l5.88 5.88c.39.39.39 1.02 0 1.41l-2.83 2.83c-.39.38-1.02.38-1.41 0l-1.3-1V20a1 1 0 0 1-1 1"
    />
  </Icon>
);

export const SettingsIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M12 15.5A3.5 3.5 0 0 1 8.5 12 3.5 3.5 0 0 1 12 8.5a3.5 3.5 0 0 1 3.5 3.5 3.5 3.5 0 0 1-3.5 3.5m7.43-2.53c.04-.32.07-.64.07-.97 0-.33-.03-.66-.07-1l2.11-1.63c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.31-.61-.22l-2.49 1c-.52-.39-1.06-.73-1.69-.98l-.37-2.65A.506.506 0 0 0 14 2h-4c-.25 0-.46.18-.5.42l-.37 2.65c-.63.25-1.17.59-1.69.98l-2.49-1c-.22-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64L4.57 11c-.04.34-.07.67-.07 1 0 .33.03.65.07.97l-2.11 1.66c-.19.15-.25.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1.01c.52.4 1.06.74 1.69.99l.37 2.65c.04.24.25.42.5.42h4c.25 0 .46-.18.5-.42l.37-2.65c.63-.26 1.17-.59 1.69-.99l2.49 1.01c.22.08.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.66Z"
    />
  </Icon>
);

export const ThermometerIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M15 13V5a3 3 0 0 0-6 0v8a5 5 0 1 0 6 0m-3-9a1 1 0 0 1 1 1v3h-2V5a1 1 0 0 1 1-1Z"
    />
  </Icon>
);

export const ThreeDotsIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M16 12a2 2 0 0 1 2-2 2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2m-6 0a2 2 0 0 1 2-2 2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2m-6 0a2 2 0 0 1 2-2 2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2Z"
    />
  </Icon>
);

export const ToolIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="m13.78 15.3 6 6 2.11-2.16-6-6-2.11 2.16m3.72-5.2c-.39 0-.81-.05-1.14-.19L4.97 21.25l-2.11-2.11 7.41-7.4L8.5 9.96l-.72.7-1.45-1.41v2.86l-.7.7-3.52-3.56.7-.7h2.81l-1.4-1.41 3.56-3.56a2.976 2.976 0 0 1 4.22 0L9.89 5.74l1.41 1.4-.71.71 1.79 1.78 1.82-1.88c-.14-.33-.2-.75-.2-1.12a3.49 3.49 0 0 1 3.5-3.52c.59 0 1.11.14 1.58.42L16.41 6.2l1.5 1.5 2.67-2.67c.28.47.42.97.42 1.6 0 1.92-1.55 3.47-3.5 3.47Z"
    />
  </Icon>
);

export const TrophyIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M18 2c-.9 0-2 1-2 2H8c0-1-1.1-2-2-2H2v9c0 1 1 2 2 2h2.2c.4 2 1.7 3.7 4.8 4v2.08C8 19.54 8 22 8 22h8s0-2.46-3-2.92V17c3.1-.3 4.4-2 4.8-4H20c1 0 2-1 2-2V2h-4M6 11H4V4h2v7m14 0h-2V4h2v7Z"
    />
  </Icon>
);

export const WindyIcon = (props: any) => (
  <Icon width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <Path
      fill="currentColor"
      d="M4 10a1 1 0 0 1-1-1 1 1 0 0 1 1-1h8a2 2 0 0 0 2-2 2 2 0 0 0-2-2c-.55 0-1.05.22-1.41.59a.973.973 0 0 1-1.42 0c-.39-.39-.39-1.03 0-1.42C9.9 2.45 10.9 2 12 2a4 4 0 0 1 4 4 4 4 0 0 1-4 4H4m15 2a1 1 0 0 0 1-1 1 1 0 0 0-1-1c-.28 0-.53.11-.71.29a.996.996 0 0 1-1.41 0c-.38-.39-.38-1.02 0-1.41C17.42 8.34 18.17 8 19 8a3 3 0 0 1 3 3 3 3 0 0 1-3 3H5a1 1 0 0 1-1-1 1 1 0 0 1 1-1h14m-1 6H4a1 1 0 0 1-1-1 1 1 0 0 1 1-1h14a3 3 0 0 1 3 3 3 3 0 0 1-3 3c-.83 0-1.58-.34-2.12-.88-.38-.39-.38-1.02 0-1.41a.996.996 0 0 1 1.41 0c.18.18.43.29.71.29a1 1 0 0 0 1-1 1 1 0 0 0-1-1Z"
    />
  </Icon>
);
