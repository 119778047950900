import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { RouteProp } from "@react-navigation/native";
import {
  Box,
  Button,
  Center,
  CheckCircleIcon,
  CheckIcon,
  DeleteIcon,
  FlatList,
  Input,
  Spacer,
  Spinner,
  Text,
} from "native-base";
import { update } from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import { AppBar } from "../../components/AppBar";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useI18n } from "../../hooks/use18n";
import { IItem } from "../../types";
import { alert } from "../../utils/Alert";
import { capitalizeWordsInString } from "../../utils/capitalizeWordsInString";
import { useItems } from "../Items/hooks/useItems";
import { EditItemRow } from "./components/EditItemRow";
import { useJournalEntry } from "./hooks/useJournalEntry";
import { putJournalEntry } from "./journalSlice";
import { IEntry, IParamList } from "./types";
import { filterByArrayInclude, filterItemsDescByQuery } from "./utils";

const Tabs = createMaterialTopTabNavigator();

type EditScreenRouteProp = RouteProp<IParamList, "EditItems">;

interface IProps {
  route: EditScreenRouteProp;
}

export const EditItems: React.FunctionComponent<IProps> = (props) => {
  const { translate } = useI18n();
  const journalId = useAppSelector((state) => state.currentJournalId);
  const { clothing, food, firstAid, materials, tools, isLoading } = useItems();
  const dispatch = useAppDispatch();
  const { targetJournalEntry } = useJournalEntry(props.route.params.entryId);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJournalItems, setSelectedJournalItems] = useState<
    IEntry["items"]
  >(targetJournalEntry?.items || []);
  const [isShowingOnlyChecked, setIsShowingOnlyChecked] = useState(
    props.route.params.initiallyShowOnlyChecks || false
  );
  useEffect(() => {
    if (targetJournalEntry?.id) {
      dispatch(
        putJournalEntry({
          journalId,
          entry: { id: targetJournalEntry.id, items: selectedJournalItems },
        })
      );
    }
  }, [selectedJournalItems]);

  const tabs = [
    { title: translate("modules.item.clothing"), data: clothing },
    { title: translate("modules.item.food"), data: food },
    { title: translate("modules.item.first-aid"), data: firstAid },
    { title: translate("modules.item.tools"), data: tools },
    { title: translate("modules.item.materials"), data: materials },
  ];

  const handleItemPress = useCallback(
    (newJournalItem: IEntry["items"][0]) => {
      setSelectedJournalItems((prev) => {
        const newJournalItemIndex = prev.findIndex(
          (item) => newJournalItem.id === item.id
        );
        // The item already exists
        if (newJournalItemIndex !== -1) {
          if (newJournalItem.quantity === 0) {
            // Remove
            return prev.filter((i) => i.id !== newJournalItem.id);
          } else {
            return update(newJournalItemIndex, newJournalItem, prev);
          }
        } else {
          return [...prev, newJournalItem];
        }
      });
    },
    [selectedJournalItems]
  );

  const toggleIsShowingOnlyChecked = () =>
    setIsShowingOnlyChecked((prev) => !prev);

  const clearItems = useCallback(() => {
    alert("Clear Items", "Are you sure you want to reset all items?", [
      {
        text: capitalizeWordsInString(translate("common.cancel")),
        style: "cancel",
      },
      {
        text: capitalizeWordsInString(translate("common.ok")),
        style: "destructive",
        onPress: () => {
          setSelectedJournalItems([]);
        },
      },
    ]);
  }, []);

  const renderItem = useCallback(
    ({ item }: { item: IItem }) => {
      const targetItem = targetJournalEntry?.items.find(
        ({ id }) => id === item.id
      );
      return (
        <EditItemRow
          quantity={targetItem?.quantity || 0}
          onQuantityChange={handleItemPress}
          item={item}
        />
      );
    },
    [selectedJournalItems]
  );

  const renderEmptyMessage = useCallback(() => {
    return (
      <Center>
        {isShowingOnlyChecked && searchQuery === "" && (
          <>
            <Text textTransform="capitalize">
              {translate("modules.journal.no-selected-items")}
            </Text>
            <Spacer size="4" />
            <Button variant="outlined" onPress={toggleIsShowingOnlyChecked}>
              {translate("modules.journal.show-all-items")}
            </Button>
          </>
        )}
        {searchQuery !== "" && (
          <>
            <Text>
              {translate("modules.journal.empty-search-results", {
                term: searchQuery,
              })}
            </Text>
            <Spacer size="4" />
            <Button variant="outlined" onPress={() => setSearchQuery("")}>
              {translate("modules.journal.clear-search")}
            </Button>
          </>
        )}
      </Center>
    );
  }, [searchQuery]);

  const actions = [
    {
      icon: isShowingOnlyChecked ? CheckCircleIcon : CheckIcon,
      onPress: toggleIsShowingOnlyChecked,
    },
    {
      icon: DeleteIcon,
      onPress: clearItems,
    },
  ];

  const screenOptions = { lazy: true, tabBarScrollEnabled: true };

  return (
    <Box flex="1">
      <AppBar
        goBack
        title={translate("modules.journal.add items")}
        actions={actions}
      />

      <Input
        value={searchQuery}
        onChangeText={setSearchQuery}
        placeholder={"Search"}
      />
      <Tabs.Navigator screenOptions={screenOptions}>
        {tabs.map((tab) => {
          const data = tab.data
            .filter((item) =>
              filterItemsDescByQuery(searchQuery, {
                desc: item.id,
              })
            )
            .filter((item) =>
              isShowingOnlyChecked
                ? filterByArrayInclude(
                    selectedJournalItems.map((item) => item.id),
                    item
                  )
                : true
            );
          return (
            <Tabs.Screen key={tab.title} name={tab.title}>
              {() =>
                isLoading ? (
                  <Center flex="1">
                    <Spinner color="white" size={50} />
                  </Center>
                ) : (
                  <FlatList
                    ListEmptyComponent={renderEmptyMessage}
                    renderItem={renderItem}
                    data={data}
                  />
                )
              }
            </Tabs.Screen>
          );
        })}
      </Tabs.Navigator>
    </Box>
  );
};
