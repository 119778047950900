import { useGetMaterialsQuery } from "../../../generated/graphql";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { getUserLocale } from "../../../utils/userLocaleSlice";

export function useMaterials() {
  const locale = useAppSelector(getUserLocale);
  const results = useGetMaterialsQuery({ variables: { locale } });
  const materialsData = results?.data?.materials ?? [];
  const formattedData = materialsData.map((materialItem) => {
    return {
      ...materialItem,
      name: materialItem.name_translations
        ? materialItem.name_translations[0].text
        : "",
    };
  });

  return { ...results, data: formattedData };
}
