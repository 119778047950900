import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation, useTheme } from "@react-navigation/native";
import React from "react";
import {
  LayoutAnimation,
  Platform,
  StyleSheet,
  UIManager,
  View,
} from "react-native";
import { Box, Button, CheckIcon, Pressable, Row, Text } from "native-base";
import { useI18n } from "../../../hooks/use18n";
import { sharedStyles, spacing } from "../../../sharedStyles";
import { ITrophy, ITrophyType } from "../types";

if (Platform.OS === "android") {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

interface IProps {
  trophyType: ITrophyType;
  trophy: ITrophy;
  isChecked: boolean;
  onPress: (id: ITrophy["id"]) => void;
}

export const TrophyCard: React.FunctionComponent<IProps> = (props) => {
  const theme = useTheme();
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const { translate } = useI18n();

  const handlePress = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    props.onPress(props.trophy.id);
  };

  const tXTitle = `modules.trophies.trophies.${props.trophyType}.${props.trophy.id}.name`;
  const tXDesc = `modules.trophies.trophies.${props.trophyType}.${props.trophy.id}.desc`;

  return (
    <View style={[cardStyles(theme).container]}>
      <Pressable
        onPress={handlePress}
        style={[sharedStyles.row, sharedStyles.itemsCenter]}
      >
        <>
          {props.trophyType === "custom" ? (
            <Box>
              <Text fontSize="xl">{props.trophy.name}</Text>
              <Text>{props.trophy.desc}</Text>
            </Box>
          ) : (
            <Box>
              <Text fontSize="lg">
                {/* @ts-ignore */}
                {translate(tXTitle)}
              </Text>
              <Text>
                {/* @ts-ignore */}
                {translate(tXDesc)}
              </Text>
            </Box>
          )}
        </>
      </Pressable>
      <Row mt="2" justifyContent="flex-end">
        {props.trophyType === "custom" && (
          <Button
            onPress={() =>
              navigation.navigate("AddCustomTrophy", {
                trophyId: props.trophy.id,
              })
            }
            variant="ghost"
          >
            {/* @TODO: Move to Common */}
            <Text color="primary.500" textTransform="capitalize">
              {translate("modules.journal.edit")}
            </Text>
          </Button>
        )}
        <Button leftIcon={<CheckIcon />} onPress={handlePress} variant="ghost">
          <Text color="primary.500" textTransform="capitalize">
            Complete
          </Text>
        </Button>
      </Row>
    </View>
  );
};

const cardStyles = (theme: any) =>
  StyleSheet.create({
    buttonRow: {
      flex: 1,
      justifyContent: "flex-end",
    },
    container: {
      flex: 1,
      borderRadius: theme.roundness,
      overflow: "hidden",
      marginBottom: spacing.sm,
    },
    blurView: {},
  });
