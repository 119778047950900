import { Box, ChevronRightIcon, Row } from "native-base";
import React from "react";

export const NavigationRow: React.FunctionComponent<{
  children?: React.ReactNode;
  showChevron?: boolean;
}> = (props) => {
  return (
    <Row minH="44" alignItems="center" justifyContent="space-between">
      <Box>{props.children}</Box>
      {props.showChevron && (
        <ChevronRightIcon size={3} color="white" name="chevron-right" />
      )}
    </Row>
  );
};

NavigationRow.defaultProps = {
  showChevron: true,
};
