import { DrawerNavigationProp } from "@react-navigation/drawer";
import { RouteProp, useNavigation } from "@react-navigation/native";
import { Button, Column, Input } from "native-base";
import React, { useCallback, useEffect, useState } from "react";
import { KeyboardAvoidingView, Platform } from "react-native";
import { AppBar } from "../../components/AppBar";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { sharedStyles } from "../../sharedStyles";
import { useJournalEntry } from "./hooks/useJournalEntry";
import { putJournalEntry } from "./journalSlice";
import { IParamList } from "./types";

type EditScreenRouteProp = RouteProp<IParamList, "EditDay">;

interface IProps {
  route: EditScreenRouteProp;
}

export const EditDay: React.FunctionComponent<IProps> = (props) => {
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const journalId = useAppSelector((state) => state.currentJournalId);
  const dispatch = useAppDispatch();
  const { targetJournalEntry } = useJournalEntry(props.route.params.entryId);
  const [day, setDay] = useState(String(targetJournalEntry?.day || 1));

  useEffect(() => {
    if (targetJournalEntry?.id) {
      dispatch(
        putJournalEntry({
          journalId,
          entry: { id: targetJournalEntry.id, day: Number(day) },
        })
      );
    }
  }, [day]);

  const handleDayChange = useCallback((newDay: string) => {
    setDay(newDay);
  }, []);

  return (
    <KeyboardAvoidingView
      behavior={Platform.select({ ios: "padding" })}
      style={[sharedStyles.flex1]}
    >
      <AppBar goBack title={"Edit Day"} />
      <Column justifyContent="space-between" flex="1">
        <Input
          autoFocus
          keyboardType={"number-pad"}
          value={String(day)}
          onChangeText={handleDayChange}
          placeholder="Day"
        />
        <Button mb="4" onPress={navigation.goBack}>
          Go Back
        </Button>
      </Column>
    </KeyboardAvoidingView>
  );
};
