import { ApolloProvider } from "@apollo/client";
import {
  DarkTheme,
  DocumentTitleOptions,
  LinkingOptions,
  NavigationContainer,
} from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import { NativeBaseProvider, extendTheme } from "native-base";
import React, { useCallback, useEffect } from "react";
import { Platform, SafeAreaView, Text } from "react-native";
import "react-native-gesture-handler";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { enableScreens } from "react-native-screens";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { MainNavigation } from "./src/MainNavigation";
import { backgroundColor, colors } from "./src/colors";
import "./src/config/i18n";
import { useApolloClient } from "./src/hooks/useApolloClient";
import { RouteNames } from "./src/modules/Maps/constants";
import { sharedStyles } from "./src/sharedStyles";
import { persistor, store } from "./src/store";
import { capitalizeWordsInString } from "./src/utils/capitalizeWordsInString";
import * as SplashScreen from "expo-splash-screen";

SplashScreen.preventAutoHideAsync();

enableScreens();

const App: React.FunctionComponent = () => {
  const { client } = useApolloClient();

  useEffect(() => {
    if (Platform.OS === "web") {
      if (document.querySelector("html")) {
        document.querySelector("html")!.style.background = backgroundColor;
      }
    }
  }, []);

  const theme = extendTheme({
    colors: {
      primary: colors.primary,
    },
    config: {
      // Changing initialColorMode to 'dark'
      initialColorMode: "dark",
    },
  });

  const linking: LinkingOptions<ReactNavigation.RootParamList> | undefined = {
    prefixes: [],
    config: {
      screens: {
        Drawer: "",
        [RouteNames.ChangeLog.name]: RouteNames.ChangeLog.route,
        [RouteNames.Clothing.name]: RouteNames.Clothing.name,
        [RouteNames.Dashboard.name]: RouteNames.Dashboard.name,
        [RouteNames.FirstAid.name]: RouteNames.FirstAid.route,
        [RouteNames.Food.name]: RouteNames.Food.name,
        [RouteNames.Items.name]: RouteNames.Items.name,
        [RouteNames.Journal.name]: {
          screens: { default: RouteNames.Journal.name },
        },
        [RouteNames.Maps.name]: RouteNames.Maps.name,
        [RouteNames.Map.name]: RouteNames.Map.route,
        [RouteNames.Materials.name]: RouteNames.Materials.name,
        [RouteNames.Region.name]: RouteNames.Region.route,
        [RouteNames.Settings.name]: RouteNames.Settings.name,
        [RouteNames.Tools.name]: RouteNames.Tools.name,
        [RouteNames.Trophies.name]: {
          screens: { [RouteNames.Trophies.name]: RouteNames.Trophies.name },
        },
      },
    },
  };

  const documentTitle: DocumentTitleOptions = {
    formatter: (options, route) =>
      `${
        options?.title ?? capitalizeWordsInString(route?.name || "")
      } - Extended Night Companion`,
  };

  const hideSplashScreen = useCallback(async () => {
    await SplashScreen.hideAsync();
  }, []);

  if (!client) return null;

  return (
    <GestureHandlerRootView style={sharedStyles.flex1}>
      <SafeAreaView style={[sharedStyles.flex1, sharedStyles.background]}>
        <Provider store={store}>
          <PersistGate persistor={persistor} onBeforeLift={hideSplashScreen}>
            <NavigationContainer
              linking={linking}
              theme={{
                ...DarkTheme,
                colors: {
                  primary: backgroundColor,
                  background: backgroundColor,
                  card: backgroundColor,
                  text: "white",
                  border: backgroundColor,
                  notification: backgroundColor,
                },
              }}
              documentTitle={documentTitle}
            >
              <NativeBaseProvider theme={theme}>
                <StatusBar translucent style="light" />
                <ApolloProvider client={client}>
                  <MainNavigation />
                </ApolloProvider>
              </NativeBaseProvider>
            </NavigationContainer>
          </PersistGate>
        </Provider>
      </SafeAreaView>
    </GestureHandlerRootView>
  );
};

export default App;
