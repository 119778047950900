import { DrawerNavigationProp } from "@react-navigation/drawer";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { Box, Button, Column, Input, KeyboardAvoidingView } from "native-base";
import React, { useState } from "react";
import { Platform } from "react-native";
import { AppBar } from "../../components/AppBar";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { createUniqueId } from "../../utils/sideEffects";
import { addJournal, putJournal } from "./journalSlice";
import { IParamList } from "./types";

type AddJournalParams = RouteProp<IParamList, "AddJournal">;

export const AddJournal: React.FunctionComponent = () => {
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const dispatch = useAppDispatch();
  const route = useRoute<AddJournalParams>();
  const targetJournal = route.params?.journal;

  const [name, setName] = useState(targetJournal?.name || "");
  const [desc, setDesc] = useState(targetJournal?.desc || "");

  function handleSave(): void {
    if (targetJournal?.id) {
      dispatch(putJournal({ ...targetJournal, name, desc }));
    } else {
      dispatch(addJournal({ entries: [], id: createUniqueId(), name, desc }));
    }
    navigation.goBack();
  }

  return (
    <Box flex="1">
      <AppBar goBack title="Add Journal" />
      <KeyboardAvoidingView
        behavior={Platform.select({ ios: "padding" })}
        flex="1"
      >
        <Column space="2" mb="4">
          <Input
            placeholder="Title"
            onChangeText={setName}
            value={name}
            mb="2"
          />
          <Input
            multiline
            numberOfLines={6}
            placeholder="Description"
            onChangeText={setDesc}
            value={desc}
            mb="2"
          />
        </Column>
        <Column space="2">
          <Button onPress={handleSave} mb="2">
            Save
          </Button>
          <Button variant="ghost" onPress={navigation.goBack} mb="2">
            Cancel
          </Button>
        </Column>
      </KeyboardAvoidingView>
    </Box>
  );
};
