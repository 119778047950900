export const RouteNames = {
  Attributions: { name: "attributions" },
  ChangeLog: { name: "changeLog", route: "change-log" },
  Clothing: { name: "clothing" },
  Dashboard: { name: "dashboard" },
  Drawer: { name: "drawer" },
  FirstAid: { name: "firstAid", route: "first-aid" },
  Food: { name: "food" },
  Items: { name: "items" },
  Journal: { name: "journal" },
  Map: { name: "map", route: "maps/:name/:type" },
  Maps: { name: "maps" },
  Materials: { name: "materials" },
  Region: { name: "region", route: "maps/:regionName" },
  Settings: { name: "settings" },
  Tools: { name: "tools" },
  Trophies: { name: "trophies" },
};

export enum RegionDifficultyEnum {
  ADVANCED = "advanced",
  BEGINNER = "beginner",
  INTERMEDIATE = "intermediate",
}
