import { badgesData } from "./../data/badgesData";
import { challengeData } from "./../data/challengesData";
import { hiddenTrophies, trophies } from "./../data/trophiesData";
import { ITrophyType } from "./../types";

export const getTrophyData = (trophyType: ITrophyType): any => {
  return trophyType === "standard"
    ? trophies
    : trophyType === "hidden"
    ? hiddenTrophies
    : trophyType === "feat"
    ? badgesData
    : trophyType === "challenge"
    ? challengeData
    : [];
};
