import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { hasPath } from "ramda";
import { IPin } from "./types";

interface IState {
  [mapId: string]: {
    [mapTYpe: string]: IPin[];
  };
}

const initialState: IState = {};

export const pinSlice = createSlice({
  name: "mapPin",
  initialState,
  reducers: {
    addPin: (
      state,
      action: PayloadAction<{ mapId: string; mapType: string; pin: IPin }>
    ) => {
      const { mapId, mapType, pin } = action.payload;

      if (hasPath([mapId, mapType], state)) {
        state[mapId][mapType].push(pin);
      } else {
        return {
          ...state,
          [mapId]: {
            [mapType]: [pin],
            ...state[mapId],
          },
        };
      }
    },
    deletePin: (
      state,
      action: PayloadAction<{
        mapId: string;
        mapType: string;
        pinID: IPin["id"];
      }>
    ) => {
      const { mapId, mapType, pinID } = action.payload;
      const targetMapPins = state[mapId][mapType];
      state[mapId][mapType] = targetMapPins.filter((pin) => pin.id !== pinID);
    },
    putPin: (
      state,
      action: PayloadAction<{
        mapId: string;
        mapType: string;
        pin: Partial<IPin>;
      }>
    ) => {
      const { mapId, mapType, pin } = action.payload;

      if (hasPath([mapId, mapType], state)) {
        const targetPins = state[mapId][mapType];
        const index = targetPins.findIndex((p) => p.id === pin.id);
        const targetPin = targetPins[index];
        targetPins[index] = { ...targetPin, ...pin };
      }
    },
  },
});

export const { addPin, deletePin, putPin } = pinSlice.actions;

export default pinSlice.reducer;
