import { createSlice } from "@reduxjs/toolkit";
import { getLocales } from "expo-localization";
import { RootState } from "../../store";

// Types
type WeightDisplayMode = "kilograms" | "pounds";
type TemperatureDisplayMode = "fahrenheit" | "celsius";

interface State {
  weight: WeightDisplayMode | undefined;
  temperature: TemperatureDisplayMode | undefined;
}

const initialState: State = {
  weight: undefined,
  temperature: undefined,
};

export const unitDisplayModeSlice = createSlice({
  name: "unitDisplayModeSlice",
  initialState,
  reducers: {
    setDefaultUnitDisplayMode: (state) => {
      state.temperature = "fahrenheit";
      state.weight = "pounds";
    },
    setUnitDisplayModeByDevice: (state) => {
      const isDeviceUsingMetric = getIsDeviceUsingMetric();

      if (isDeviceUsingMetric) {
        state.temperature = "celsius";
        state.weight = "kilograms";
      } else {
        state.temperature = "fahrenheit";
        state.weight = "pounds";
      }
    },
    toggleWeightDisplayMode: (state) => {
      if (state.weight === "pounds") {
        state.weight = "kilograms";
      } else {
        state.weight = "pounds";
      }
    },
    toggleTemperatureDisplayMode: (state) => {
      if (state.temperature === "fahrenheit") {
        state.temperature = "celsius";
      } else {
        state.temperature = "fahrenheit";
      }
    },
  },
});

export const {
  setDefaultUnitDisplayMode,
  setUnitDisplayModeByDevice,
  toggleWeightDisplayMode,
  toggleTemperatureDisplayMode,
} = unitDisplayModeSlice.actions;

export default unitDisplayModeSlice.reducer;

// Helpers
function getIsDeviceUsingMetric() {
  const locales = getLocales();
  const locale = locales[0];
  const { measurementSystem, regionCode } = locale;

  if (measurementSystem === "us" || regionCode?.toLowerCase() === "us") {
    return false;
  } else {
    return true;
  }
}

// Selectors
export function getTemperatureDisplayMode(
  state: RootState
): State["temperature"] {
  return state.unitDisplayMode.temperature;
}

export function getWeightDisplayMode(state: RootState): State["weight"] {
  return state.unitDisplayMode.weight;
}

export function getIsMeasurmentPreferenceSet(state: RootState): boolean {
  const currentWeightDisplayMode = state.unitDisplayMode.weight;
  const currentTemperatureDisplayMode = state.unitDisplayMode.temperature;

  // Only set user's preference if they've never set it
  if (
    currentTemperatureDisplayMode === undefined &&
    currentWeightDisplayMode === undefined
  ) {
    return false;
  } else {
    return true;
  }
}
