import { RouteProp, useRoute } from "@react-navigation/native";
import React from "react";
import { RootStackParamList } from "../../types";
import { regions } from "./data/regionsData";
import { RegionDetail } from "./RegionDetail";
import { IRegionNames } from "./types";

export interface RegionDetailScreenParams {
  regionName: IRegionNames;
}

export const RegionDetailScreen: React.FunctionComponent = () => {
  const route = useRoute<RouteProp<RootStackParamList, "region">>();
  const targetName = route.params?.regionName;
  const region = regions.find((region) => region.name === targetName);

  return region ? <RegionDetail region={region} /> : null;
};
