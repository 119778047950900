import { Box } from "native-base";
import React from "react";
import { Platform } from "react-native";

interface ScreenContainerProps {
  children?: React.ReactNode;
}

export const ScreenContainer: React.FunctionComponent<ScreenContainerProps> = (
  props
) => {
  const isWeb = Platform.OS === "web";

  if (isWeb) {
    return <Box>{props.children}</Box>;
  } else {
    return <>{props.children}</>;
  }
};
