import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IAvailableLanguages } from "../types";
import { getLocales } from "expo-localization";
import { SUPPORTED_LOCALES } from "../Constants";

// Constants
const DEFAULT_LANGUAGE = "en";

//Selectors
export function getUserLocale(state: RootState): string {
  return state.userLocale;
}

export function getShouldSetUserLocaleByDevice(state: RootState): boolean {
  const currentLocale = state.userLocale;
  const usingDefaultLocale = currentLocale === "";
  const locales = getLocales();
  const locale = locales[0];
  const { languageCode } = locale;

  if (usingDefaultLocale && SUPPORTED_LOCALES.includes(languageCode)) {
    return true;
  } else {
    return false;
  }
}

// Slice
export const userLocale = createSlice({
  name: "userLocale",
  initialState: "",
  reducers: {
    setLocale: (state, action: PayloadAction<IAvailableLanguages>) => {
      return action.payload ? action.payload : state;
    },
    setDefaultLocale: () => {
      return DEFAULT_LANGUAGE;
    },
    setLocaleByDevice: () => {
      const locales = getLocales();
      const locale = locales[0];
      const { languageCode = "" } = locale;

      return languageCode;
    },
  },
});

export const { setLocale, setDefaultLocale, setLocaleByDevice } =
  userLocale.actions;

export default userLocale.reducer;
