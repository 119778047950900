import { Box, Column, Pressable } from "native-base";
import React from "react";
export interface SurfaceCardProps {
  children?: React.ReactNode;
  onPress?: () => void;
}
export const SurfaceCard: React.FunctionComponent<SurfaceCardProps> = (
  props
) => {
  return (
    <Pressable
      onPress={props.onPress}
      bg="primary.800"
      flex="1"
      overflow="hidden"
      rounded="md"
    >
      <Box padding="4">
        <Column justifyContent="flex-start" alignItems="flex-start">
          {props.children}
        </Column>
      </Box>
    </Pressable>
  );
};
