import { useGetFirstAidQuery } from "../../../generated/graphql";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { getUserLocale } from "../../../utils/userLocaleSlice";

export function useFirstAid() {
  const locale = useAppSelector(getUserLocale);
  const results = useGetFirstAidQuery({ variables: { locale } });
  const firstAidData = results?.data?.firstAids ?? [];
  const formattedData = firstAidData.map((firstAidItem) => {
    return {
      ...firstAidItem,
      name: firstAidItem.name_translations
        ? firstAidItem.name_translations[0].text
        : "",
    };
  });

  return { ...results, data: formattedData };
}
