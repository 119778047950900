import { Pressable, Row, Spacer, Text } from "native-base";
import React, { memo } from "react";
import { IItem } from "../../../types";
import { kebabToTitleCase } from "../../../utils/calculations";
import { IEntry } from "../types";
import { PositiveNumberStepper } from "./PositiveNumberStepper";

interface IProps {
  onQuantityChange: (newJournalItem: IEntry["items"][0]) => void;
  item: IItem;
  quantity: number;
}

const EditItemRow: React.FunctionComponent<IProps> = (props) => {
  return (
    <>
      <Spacer h="2" />

      <Pressable>
        <Row justifyContent="space-between" alignItems="center">
          <Text numberOfLines={1} flex="1">
            {kebabToTitleCase(props.item.desc)}
          </Text>
          <PositiveNumberStepper
            onChange={(num) =>
              props.onQuantityChange({ id: props.item.id, quantity: num })
            }
            initialNumber={props.quantity}
          />
        </Row>
      </Pressable>
    </>
  );
};

const MemoEditItemRow = memo(
  EditItemRow,
  (prevProps, props) =>
    prevProps.item.id === props.item.id || prevProps.quantity === props.quantity
);

export { MemoEditItemRow as EditItemRow };
