import { normalizeLocale } from "../config/normalizeLocale";

const languages = {
  fr: "French",
  en: "English",
  pl: "Polskie",
  ru: "Pусский",
};
export function getLanguageDisplayName(languageCode: string): string {
  const normalizedCode = normalizeLocale(languageCode);
  // @ts-ignore
  return languages[normalizedCode];
}
