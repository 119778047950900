import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { migrateAsyncStorageFavorite } from "../modules/Maps/favoriteRegionSlice";
import {
  getIsMeasurmentPreferenceSet,
  setUnitDisplayModeByDevice,
} from "../modules/Items/unitDisplayModeSlice";
import {
  getShouldSetUserLocaleByDevice,
  setLocaleByDevice,
} from "../utils/userLocaleSlice";

export function useAppSetup() {
  const dispatch = useAppDispatch();
  const isMeasurmentPreferenceSet = useAppSelector(
    getIsMeasurmentPreferenceSet
  );
  const shouldSetUserLocaleByDevice = useAppSelector(
    getShouldSetUserLocaleByDevice
  );

  useEffect(() => {
    dispatch(migrateAsyncStorageFavorite());

    if (!isMeasurmentPreferenceSet) {
      dispatch(setUnitDisplayModeByDevice());
    }

    /**`
     * Could see this living in the useI18n hook
     * But leaving here for now.
     */
    if (shouldSetUserLocaleByDevice) {
      dispatch(setLocaleByDevice());
    }
  }, []);
}
