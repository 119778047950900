import { useEffect } from "react";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { IEntry } from "../types";

export const useJournalEntry = (
  entryId: IEntry["id"]
): { targetJournalEntry: IEntry | undefined } => {
  const journalId = useAppSelector((state) => state.currentJournalId);
  const journals = useAppSelector((state) => state.journals[journalId]);

  const targetJournalEntry = journals.entries.find(
    (journal) => journal.id === entryId
  );

  useEffect(() => {
    if (!targetJournalEntry && entryId) {
      const err = `You passed in an entry ID: "${entryId}", but there is no entry for that ID`;
      throw new Error(err);
    }
  }, [entryId, targetJournalEntry]);

  return { targetJournalEntry };
};
