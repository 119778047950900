import { Box, Column, Divider, Image, Row, Text } from "native-base";
import React from "react";
import {
  Clothe,
  FirstAid,
  Food,
  Material,
  Tool,
} from "../../../generated/graphql";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { DisplayWeight } from "./DisplayWeight";
import { getWeightDisplayMode } from "../unitDisplayModeSlice";

type Item = Clothe | FirstAid | Material | Food | Tool;

const ItemCardBody: React.FunctionComponent<Item> = (props) => {
  const weightDisplayMode = useAppSelector(getWeightDisplayMode);
  return (
    <Row justifyContent={"space-between"}>
      <Column>
        <Text fontSize={"xl"} selectable>
          {props.name}
        </Text>
        <Text _android={{ my: 1 }} _ios={{ my: 1 }} selectable>
          {props.weight && weightDisplayMode && (
            <DisplayWeight
              displayMode={weightDisplayMode}
              weightInKg={props.weight}
            />
          )}
        </Text>
      </Column>
    </Row>
  );
};

interface IProps {
  item: Item;
  children?: JSX.Element;
}

export const ItemCard: React.FunctionComponent<IProps> = (props) => {
  return (
    <Row w="full" mb="2">
      <Box w="75" h="75" mr="4" rounded="md" bg="primary.600">
        {props.item.image?.url && (
          <Image
            alt={props.item.name ?? ""}
            w="75"
            h="75"
            source={{ uri: props.item.image.url }}
          />
        )}
      </Box>
      <Column flex={1}>
        <ItemCardBody
          id={props.item.id}
          image={props.item.image}
          weight={props.item.weight}
          name={props.item.name}
        />
        {props.children && (
          <Box>
            <Divider mb="1" bg="primary.400" />
            <Box w={["full", "full", "full", "1/2"]}>{props.children}</Box>
          </Box>
        )}
      </Column>
    </Row>
  );
};
