import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetRegionsQuery } from "../../../generated/graphql";
import { getFavoriteRegion } from "../favoriteRegionSlice";
import { IRegion } from "../types";
import { makeSortFavoriteRegionFront } from "../utils/makeSortFavoriteRegionFront";
import { regions as regionsData } from "./../data/regionsData";

export function useRegions(): IRegion[] {
  const [regions, setRegions] = useState<IRegion[]>(regionsData);

  const favorite = useSelector(getFavoriteRegion);
  const sortFavoriteRegionFront = makeSortFavoriteRegionFront(favorite);
  const { data } = useGetRegionsQuery();

  useEffect(() => {
    const regionsWithImages = regionsWithFavoriteInFront.map((region) => {
      const matchingRegion = data?.regions?.find(
        (dataRegion) => dataRegion.originalID === region.name
      );
      const image = matchingRegion?.cover_image?.url || region.image;

      return { ...region, image };
    });

    setRegions(regionsWithImages);
  }, [data]);

  const sortedRegions = [...regions].sort(function (a, b) {
    const nameA = a.name.toLowerCase(),
      nameB = b.name.toLowerCase();
    if (nameA < nameB)
      //sort string ascending
      return -1;
    if (nameA > nameB) return 1;
    return 0; //default return value (no sorting)
  });
  const regionsWithFavoriteInFront = [...sortedRegions].sort(
    sortFavoriteRegionFront
  );

  return regionsWithFavoriteInFront;
}
