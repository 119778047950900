import { createStackNavigator } from "@react-navigation/stack";
import React, { useState } from "react";
import { CustomTrophyContext } from "../../contexts";
import { ITrophy } from "./types";
import { AddCustomTrophy } from "./AddCustomTrophy";
import { Trophies } from "./Trophies";
import { Trophy } from "./Trophy";
import { RouteNames } from "../Maps/constants";

const TrophyStack = createStackNavigator();

export const TrophyNavigator = (): JSX.Element => {
  const [customTrophies, setCustomTrophies] = useState<ITrophy[]>([]);

  return (
    <CustomTrophyContext.Provider
      value={{
        customTrophies,
        setCustomTrophies,
      }}
    >
      <TrophyStack.Navigator screenOptions={{ headerShown: false }}>
        <TrophyStack.Screen
          component={Trophies}
          name={RouteNames.Trophies.name}
        />
        <TrophyStack.Screen name="custom">
          {() => <Trophy trophyType="custom" />}
        </TrophyStack.Screen>
        <TrophyStack.Screen
          component={AddCustomTrophy}
          name="AddCustomTrophy"
        />
        <TrophyStack.Screen name="challenge">
          {() => <Trophy trophyType="challenge" />}
        </TrophyStack.Screen>
        <TrophyStack.Screen name="standard">
          {() => <Trophy trophyType="standard" />}
        </TrophyStack.Screen>
        <TrophyStack.Screen name="hidden">
          {() => <Trophy trophyType="hidden" />}
        </TrophyStack.Screen>
        <TrophyStack.Screen name="feat">
          {() => <Trophy trophyType="feat" />}
        </TrophyStack.Screen>
      </TrophyStack.Navigator>
    </CustomTrophyContext.Provider>
  );
};
