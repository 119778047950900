import { Text } from "native-base";
import React, { useCallback } from "react";
import { AppBar } from "../../components/AppBar";
import { Tool } from "../../generated/graphql";
import { useI18n } from "../../hooks/use18n";
import { ItemCard } from "./components/ItemCard";
import { ScreenContainer } from "./components/ScreenContainer";
import { useTools } from "./hooks/useTools";
import { ItemList } from "./ItemList";

export const Tools: React.FunctionComponent = () => {
  const { translate } = useI18n();
  const { data, loading } = useTools();

  const renderItem = useCallback(
    ({ item }: { item: Tool }) => (
      <ItemCard key={item.id} item={item}>
        <Text>{item.description}</Text>
      </ItemCard>
    ),
    []
  );

  return (
    <ScreenContainer>
      <AppBar goBack title={translate("modules.item.tools")} />
      <ItemList data={data} loading={loading} renderItem={renderItem} />
    </ScreenContainer>
  );
};
