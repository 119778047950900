import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import {
  Box,
  Button,
  Column,
  Divider,
  FavouriteIcon,
  FlatList,
  Image,
  Pressable,
  Row,
  ScrollView,
  Text,
} from "native-base";
import React, { useCallback } from "react";
import { LayoutAnimation, Platform, UIManager } from "react-native";
import { useSelector } from "react-redux";
import { AppBar } from "../../components/AppBar";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { useI18n } from "../../hooks/use18n";
import { ScreenContainer } from "../Items/components/ScreenContainer";
import { RouteNames } from "./constants";
import {
  clearFavoriteRegion,
  getFavoriteRegion,
  putFavoriteRegion,
} from "./favoriteRegionSlice";
import { useRegions } from "./hooks/useRegions";
import { IRegion } from "./types";
import { MapIcon } from "../../components/Icons";

if (Platform.OS === "android") {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

export const Maps: React.FunctionComponent = () => {
  const { translate } = useI18n();
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const favorite = useSelector(getFavoriteRegion);
  const dispatch = useAppDispatch();

  const regions = useRegions();
  const handleFavoritePress = (item: IRegion) => {
    if (item.name === favorite) {
      dispatch(clearFavoriteRegion());
    } else {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      dispatch(
        putFavoriteRegion({
          newFavoriteRegion: item.name,
        })
      );
    }
  };

  const handleCardPress = (region: IRegion) => {
    navigation.navigate({
      name: RouteNames.Region.name,
      params: { regionName: region.name },
    });
  };

  const renderItem = useCallback(
    ({ item: r }: { item: IRegion }) => (
      <Box w="full" key={r.name}>
        <Pressable onPress={() => handleCardPress(r)} rounded="sm">
          <Box flexDir={["column", "row"]}>
            <Image
              w={["full", "1/3"]}
              h={[125, 125]}
              source={{ uri: r.image }}
              alt={r.name}
              rounded="md"
              mr="4"
              mb={["2", "0"]}
            />

            <Column flex="1">
              <Row alignItems={"center"}>
                <Text fontSize={"xl"} textTransform="capitalize">
                  {translate(`modules.map.${r.name}`)}
                </Text>
              </Row>
              {r.hasAmmoWorkbench || r.hasForge || r.difficulty ? (
                <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                  <Row my={[2, 0]} mr="4" space="2" alignItems="center">
                    {r.difficulty ? (
                      <Box px="4" rounded="full" background="primary.800">
                        <Text textTransform="capitalize">
                          {translate(`modules.map.difficulty.${r.difficulty}`)}
                        </Text>
                      </Box>
                    ) : null}
                    {r.hasForge ? (
                      <Row
                        px="4"
                        rounded="full"
                        background="primary.800"
                        alignItems="center"
                      >
                        <Text textTransform="capitalize">
                          {translate("modules.map.forge")}
                        </Text>
                      </Row>
                    ) : null}
                    {r.hasAmmoWorkbench ? (
                      <Row
                        px="4"
                        rounded="full"
                        background="primary.800"
                        alignItems="center"
                      >
                        <Text textTransform="capitalize">
                          {translate("modules.map.ammo-workbench")}
                        </Text>
                      </Row>
                    ) : null}
                  </Row>
                </ScrollView>
              ) : null}
              <Row justifyContent={"flex-end"}>
                <Button
                  leftIcon={
                    <FavouriteIcon
                      color={r.name === favorite ? "red.500" : "primary.200"}
                      size={4}
                      accessibilityLabel="heart"
                    />
                  }
                  onPress={() => handleFavoritePress(r)}
                  variant="ghost"
                >
                  <Text color="primary.500" textTransform="capitalize">
                    {translate("modules.map.favorite")}
                  </Text>
                </Button>
                <Button
                  leftIcon={<MapIcon color={"primary.200"} size={5} />}
                  onPress={() => handleCardPress(r)}
                  variant="ghost"
                >
                  <Text color="primary.500" textTransform="capitalize">
                    {translate("modules.map.map_plural")}
                  </Text>
                </Button>
              </Row>
            </Column>
          </Box>
        </Pressable>
      </Box>
    ),
    [favorite]
  );
  const keyExtractor = useCallback((region: IRegion) => region.name, []);
  return (
    <ScreenContainer>
      <AppBar title={translate("modules.map.region_plural")} />
      <FlatList
        data={regions}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ItemSeparatorComponent={() => (
          <Divider bg={"primary.400"} mb={["4", "6"]} mt={["2", "4"]} />
        )}
        ListFooterComponent={<Box h="16" />}
      />
    </ScreenContainer>
  );
};
