import { DrawerNavigationProp } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import {
  Box,
  ChevronRightIcon,
  FavouriteIcon,
  Image,
  Pressable,
  Row,
  Text,
} from "native-base";
import React from "react";
import { useSelector } from "react-redux";
import { useI18n } from "../../../hooks/use18n";
import { RouteNames } from "../../Maps/constants";
import { getFavoriteRegion } from "../../Maps/favoriteRegionSlice";
import { useRegions } from "../../Maps/hooks/useRegions";

export const RegionCard: React.FunctionComponent = () => {
  const region = useRegions()[0];
  const { translate } = useI18n();
  const favorite = useSelector(getFavoriteRegion);
  const navigation = useNavigation<DrawerNavigationProp<any>>();

  return (
    <Box>
      <Pressable
        onPress={() =>
          navigation.navigate({
            name: RouteNames.Region.name,
            params: { regionName: region.name },
          })
        }
      >
        <Image
          height={["48", "64", "64", "64"]}
          source={{ uri: region.image }}
          alt={region.name}
          rounded="md"
        />
        <Row mt={"1"} alignItems={"center"} justifyContent="space-between">
          <Row alignItems={"center"}>
            {favorite === region.name && (
              <FavouriteIcon
                color={"red.500"}
                size={4}
                accessibilityLabel="heart"
                mr="2"
              />
            )}
            <Text textTransform="capitalize">
              {translate(`modules.map.${region.name}`)}
            </Text>
          </Row>
          <ChevronRightIcon />
        </Row>
      </Pressable>
    </Box>
  );
};
