export const badgesData: {
  id: string;
}[] = [
  {
    id: "book-smarts",
  },
  {
    id: "cold-fusion",
  },
  {
    id: "efficient-machine",
  },
  {
    id: "fire-master",
  },
  {
    id: "free-runner",
  },
  {
    id: "snow-walker",
  },
  {
    id: "expert-trapper",
  },
  {
    id: "straight-to-the-heart",
  },
  {
    id: "blizzard-walker",
  },
  {
    id: "darkwalker",
  },
];
